import { TextInput } from "@mantine/core";

import { getPrimaryColor } from "../../helpers";

export const textInputComponentSettings = TextInput.extend({
  defaultProps: {
    color: "gray.7",
  },
  styles: (theme) => {
    const { gray } = theme.colors;
    const primaryColor = getPrimaryColor(theme);
    return {
      input: {
        transition: "color 0.1s ease, border .1s ease",
        background: gray[0],
        color: gray[7],
        borderColor: gray[3],

        // Little strange, but the way the line height works out, the padding
        // doesn't quite compute the way we'd think. It actually doesn't end
        // up mattering for plain old text components, but for other inputs
        // that inherit this padding that render with similar line-heights,
        // we need this to be somewhat consistent.
        paddingBottom: 7,
        paddingTop: 7,

        "&:hover": {
          color: gray[7],
          borderColor: gray[3],
        },
        "&:focus, &:focus-within": {
          borderColor: primaryColor[7],
        },
        "&:disabled, &[data-disabled]": {
          /*
           * By default, mantine applies a 60% opacity on disabled inputs.
           * We disable this and simply dim each of the colors in the designed
           * theme by 1, since de-opacifying the entire input prevents
           * us from showing items _inside_ the input at full opacity if we
           * desire.
           */
          opacity: 1,
          backgroundColor: gray[1],
          borderColor: gray[2],
          color: gray[4],
          "&:hover": {
            color: gray[4],
          },
        },
        "&.mantine-Input-invalid": {
          color: theme.colors.red[8],
        },
      },
      label: {
        color: gray[8],
        marginBottom: 4,
      },
      rightSection: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.colors.gray[5],
        right: 4,
      },
      error: {
        marginTop: 8,
        color: theme.colors.red[8],
      },
      description: {
        marginTop: 8,
      },
    };
  },
});

import { Group, Stack, UnstyledButton } from "@mantine/core";
import CompensationPoweredByLogo from "app/ui/assets/logo/compensation-powered-by.svg";
import Image from "next/image";
import { Link as RouterLink } from "react-router-dom";

import { HeaderLogoButton } from "./HeaderLogoButton";

function CompensationPoweredByLogoButton() {
  // return <div>hi</div>;
  return (
    <Stack h="100%" justify="center">
      <UnstyledButton component={RouterLink} to="/">
        <FifteenFiveImage />
      </UnstyledButton>
    </Stack>
  );
}

export function PoweredByFifteenFive(): JSX.Element {
  return (
    <Group wrap="nowrap" align="center" h="100%">
      <CompensationPoweredByLogoButton />
      <HeaderLogoButton height={20} width={90} style={{ marginTop: 4 }} />
    </Group>
  );
}

export function FifteenFiveImage() {
  return (
    <Image
      priority
      alt="Compensation powered by Comprehensive"
      src={CompensationPoweredByLogo}
      style={{ objectPosition: "left center", objectFit: "contain" }}
    />
  );
}

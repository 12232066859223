import { useTrackerUser } from "components/AuthProvider";
import { useAuthenticatedUserFromObjectPool } from "lib/frontend/cm/services/user";

export function isServer() {
  return typeof window === "undefined";
}

export function isBenchmarking(): boolean {
  return Boolean(
    window.location.href.match(/\/benchmarking/) &&
      !window.location.href.match(/benchmarking-dashboard/),
  );
}

function createUseSafeUser() {
  if (isServer() || isBenchmarking()) return useTrackerUser;
  return useAuthenticatedUserFromObjectPool;
}

export const useSafeCrossAppUser = createUseSafeUser();

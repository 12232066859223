import type { TextProps, MantineSize } from "@mantine/core";
import { Text, useMantineTheme } from "@mantine/core";

export type EyebrowProps = Omit<TextProps, "children"> & {
  size?: Extract<MantineSize, "sm" | "md">;
  children: React.ReactNode | React.ReactNode[];
};

// @ts-expect-error TS2322
const sizeToSizeProps: Record<Exclude<EyebrowProps["size"], undefined>, Partial<TextProps>> & {
  children: string;
} = {
  md: {
    fz: 12,
    sx: {
      lineHeight: "9px",
      letterSpacing: "2px",
    },
  },
  sm: {
    fz: 10,
    sx: {
      lineHeight: "8px",
      letterSpacing: "2px",
    },
  },
};

const useDefaultProps = () => {
  const theme = useMantineTheme();
  return {
    transform: "uppercase" as const,
    c: theme.colors.gray[6],
    fw: theme.other.fontWeights.medium,
  };
};

export const Eyebrow = ({ size = "md", ...props }: EyebrowProps) => {
  const sizeProps = sizeToSizeProps[size];
  const defaultProps = useDefaultProps();
  return <Text {...sizeProps} {...defaultProps} {...props} />;
};

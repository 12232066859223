import React, { useRef, useState } from "react";

import {
  Checkbox,
  Input,
  Button,
  Box,
  Group,
  useMantineTheme,
  Text,
  ScrollArea,
  ActionIcon,
} from "@mantine/core";
import { useFocusWithin, useWindowEvent } from "@mantine/hooks";
import { IconSearch, IconX } from "@tabler/icons-react";
import _ from "lodash";

import { NOTHING_FOUND } from "constants/explorer_selection_options";

const TOP_ELEMENT_PADDING = 6;
const CHECKBOX_ELEMENT_HEIGHT = 28;

interface CheckboxListProps {
  data: { label: string; value: string }[];
  selected?: string[];
  onChange?: (selectedValues: string[]) => void;
  maxNumberToRender?: number;
  scrollAreaHeight?: number;
  showSearch?: boolean;
  onSearchChange?: (search: string) => void;
  selectionSubtext?: string;
  onScrollToBottom?: () => void;
  disabled?: boolean;
  withClearButton?: boolean;
}

export function CheckboxList({
  data,
  selected = [],
  onChange,
  maxNumberToRender = Number.MAX_SAFE_INTEGER,
  scrollAreaHeight = 300,
  selectionSubtext,
  onSearchChange,
  showSearch = false,
  onScrollToBottom,
  disabled,
  withClearButton = true,
}: CheckboxListProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValues, setSelectedValues] = useState(selected);

  const checkboxItemsRefs = useRef<Record<string, HTMLInputElement>>({});
  const scrollAreaViewportRef = useRef<HTMLDivElement>(null);
  const { ref: focusWithinRef, focused: focusedWithin } = useFocusWithin();

  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setFocusedItemIndex(-1);
    if (onSearchChange) {
      onSearchChange(e.target.value);
    }
  };

  const handleCheckboxChange = (value: string) => {
    let nextSelectedValues: string[] = [];
    if (selectedValues.includes(value)) {
      nextSelectedValues = selectedValues.filter((v) => v !== value);
    } else {
      nextSelectedValues = [...selectedValues, value];
    }

    setSelectedValues(nextSelectedValues);

    if (onChange) {
      onChange(nextSelectedValues);
    }
  };

  const handleDeselectAll = () => {
    setSelectedValues([]);
    if (onChange) {
      onChange([]);
    }
  };

  const filteredList = data.filter(({ label }) =>
    label.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const itemsToRender = filteredList.slice(0, maxNumberToRender);

  const theme = useMantineTheme();

  let calculatedScrollAreaHeight =
    itemsToRender.length === 0
      ? 30
      : Math.min(
          scrollAreaHeight,
          itemsToRender.length * CHECKBOX_ELEMENT_HEIGHT + TOP_ELEMENT_PADDING,
        );

  if (selectionSubtext) {
    calculatedScrollAreaHeight += 30;
  }

  function getCheckboxItemRef(value: string): HTMLInputElement | null {
    return checkboxItemsRefs.current[value] || null;
  }

  function getItemRefByIndex(index: number): HTMLInputElement | null {
    return getCheckboxItemRef(filteredList[index]?.value);
  }

  function renderSelectionSubtext(): JSX.Element | undefined {
    if (selectionSubtext) {
      return (
        <Text
          fz={10}
          c="gray.6"
          sx={{
            textTransform: "uppercase",
            fontWeight: 500,
            letterSpacing: "0.08em",
            padding: "4px 0px",
          }}
        >
          {selectionSubtext}
        </Text>
      );
    }

    return undefined;
  }

  function setFocusedItemByIndex(index: number): boolean {
    if (index >= 0 && index < filteredList.length) {
      setFocusedItemIndex(index);
      return true;
    }

    return false;
  }

  const handleInputKeydown = (event: KeyboardEvent) => {
    if (!focusedWithin) {
      return;
    }

    switch (event.key) {
      case "ArrowUp": {
        event.preventDefault();

        const prevRef = getItemRefByIndex(focusedItemIndex - 1);
        if (prevRef) {
          prevRef.focus();
          setFocusedItemByIndex(focusedItemIndex - 1);
        }

        break;
      }

      case "ArrowDown": {
        event.preventDefault();

        const nextRef = getItemRefByIndex(focusedItemIndex + 1);
        if (nextRef) {
          nextRef.focus();
          setFocusedItemByIndex(focusedItemIndex + 1);
        }

        break;
      }

      case "Enter": {
        const currentFocusRef = getItemRefByIndex(focusedItemIndex);
        currentFocusRef?.focus();
        currentFocusRef?.click();
      }
    }
  };

  useWindowEvent("keydown", handleInputKeydown);

  function renderSearchRightSection(): JSX.Element | undefined {
    if (searchTerm === "") {
      return undefined;
    }

    return (
      <ActionIcon
        onClick={() => {
          setSearchTerm("");
          if (onSearchChange) {
            onSearchChange("");
          }
        }}
      >
        <IconX size={16} />
      </ActionIcon>
    );
  }

  return (
    <Box ref={focusWithinRef} sx={{ borderRadius: "4px", paddingTop: "2px" }}>
      {showSearch && (
        <Input
          placeholder="Search to see more..."
          value={searchTerm}
          onChange={handleSearchChange}
          leftSection={<IconSearch size={16} />}
          variant="unstyled"
          rightSection={renderSearchRightSection()}
          radius="sm"
          mt={6}
          sx={{
            border: "1px solid #E3E6E5",
            // borderRadius: "4px",
          }}
          styles={{
            wrapper: {
              // overflow: "visible",
              // marginTop: "4px",
              // marginBottom: "2px",
            },
          }}
        />
      )}
      <Box>
        <ScrollArea.Autosize
          mt={showSearch ? 4 : 0}
          mah={calculatedScrollAreaHeight}
          type="hover"
          scrollbarSize={8}
          viewportRef={scrollAreaViewportRef}
          onScrollPositionChange={(position) => {
            const scrollViewPort = scrollAreaViewportRef.current;

            if (_.isNil(scrollViewPort)) {
              return;
            }

            const hasScrolledToEnd =
              position.y === scrollViewPort.scrollHeight - scrollViewPort.offsetHeight;

            if (hasScrolledToEnd) {
              if (searchTerm === "" && onScrollToBottom) {
                onScrollToBottom();
              }
            }
          }}
        >
          {renderSelectionSubtext()}
          {itemsToRender.length > 0 ? (
            itemsToRender.map(({ label, value }, idx) => (
              <div
                key={value}
                onMouseEnter={() => setFocusedItemIndex(idx)}
                onMouseLeave={() => setFocusedItemIndex(-1)}
              >
                <Checkbox
                  ref={(node: HTMLInputElement) => {
                    if (checkboxItemsRefs && checkboxItemsRefs.current) {
                      // eslint-disable-next-line no-param-reassign
                      checkboxItemsRefs.current[value] = node;
                    }
                  }}
                  size="xs"
                  // this is a little weird, but its to ensure that the entire row will toggle the value when clicked
                  label={
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        cursor: "pointer",
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          paddingLeft: 24,
                        }}
                      >
                        {label}
                      </Box>
                    </Box>
                  }
                  disabled={disabled}
                  checked={selectedValues.includes(value)}
                  onChange={() => handleCheckboxChange(value)}
                  mt={idx === 0 && _.isNil(selectionSubtext) ? TOP_ELEMENT_PADDING : 0}
                  py={6}
                  styles={{
                    root: {
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 2px",
                      position: "relative",
                      cursor: "pointer",
                      borderRadius: "4px",
                      ":hover": {
                        backgroundColor: theme.colors.gray[3],
                      },
                    },
                  }}
                />
              </div>
            ))
          ) : (
            <Text size="sm" c="gray.6" px={6} py={2}>
              {NOTHING_FOUND}
            </Text>
          )}
        </ScrollArea.Autosize>
      </Box>
      {withClearButton && (
        <Box pt={8}>
          <Group justify="flex-start">
            <Button variant="secondary" h={30} onClick={handleDeselectAll}>
              Clear
            </Button>
          </Group>
        </Box>
      )}
    </Box>
  );
}

import { Button } from "@mantine/core";

import classNames from "./Button.module.css";

export const buttonComponentSettings = Button.extend({
  defaultProps: {
    variant: "tertiary",
  },
  classNames,
});

import { useTrackerViewMetadata } from "components/AuthProvider";
import { useAppSelector } from "lib/frontend/cm/store";

import { isServer, isBenchmarking } from "./useSafeCrossAppUser";

function useCmViewMetadata() {
  return useAppSelector((store) => store.core.viewMetadata) || {};
}

export function useSafeCrossAppViewMetadata() {
  if (isServer() || isBenchmarking()) return useTrackerViewMetadata;
  return useCmViewMetadata;
}

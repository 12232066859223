import { useState } from "react";

import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModel, ObjectPoolModelName, ObjectPoolEntityMap } from "utils/types";

import {
  getEntityMapFromQueryCache,
  replaceEntityMapInQueryCache,
  upsertRecordsInQueryCache,
  useBoundQueryClient,
} from "../queryCache";

// TODO: Rename this "batchUpsertMany"
export function useUpsertManyObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.upsertMany`);
  const upsertRecordMutation = trpc.useMutation(mutationPath);
  const { mutateAsync: _mutateAsync, mutate: _mutate, ...rest } = upsertRecordMutation;

  async function mutate(data: Omit<ObjectPoolModel<TModelName>, "id">[]) {
    const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);
    let modelSaveResultsArr = [] as ObjectPoolModel<TModelName>[];
    setIsMutating(true);
    try {
      // Optimistic
      upsertRecordsInQueryCache(
        boundQueryClient,
        modelName,
        // need to convert this to an entity map somehow
        // @ts-expect-error TS2339
        data.map((entry) => entry.data),
      );

      showUniversalLoader();
      const modelSaveResults = (await upsertRecordMutation.mutateAsync({
        data,
      })) as ObjectPoolEntityMap<TModelName>;
      modelSaveResultsArr = modelSaveResults.ids.map((id) => modelSaveResults.entities[id]);

      // Resolve with actual save result
      upsertRecordsInQueryCache<TModelName>(boundQueryClient, modelName, modelSaveResultsArr);
    } catch (err) {
      console.error(err);
      replaceEntityMapInQueryCache<TModelName>(
        boundQueryClient,
        modelName,
        initialEntityMapFromCache,
      );
      throw err;
    }
    hideUniversalLoader();
    setIsMutating(false);
    return modelSaveResultsArr;
  }
  return { mutate, isMutating, ...rest };
}

export function generateBoundUseUpsertManyObjectPoolMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBoundUpsertManyObjectPoolRecord() {
    const useMutationResult = useUpsertManyObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBoundUpsertManyObjectPoolRecord;
}

import React, { useState } from "react";

import { Button, Flex, TextInput } from "@mantine/core";

interface MailChimpNewsletterSignupProps {
  border?: boolean;
}

export function MailChimpNewsletterSignup(props: MailChimpNewsletterSignupProps): JSX.Element {
  const [emailValue, setEmailValue] = useState("");

  return (
    <form
      action="https://slopehr.us6.list-manage.com/subscribe/post?u=5c59f28bbd0cfb8aa0fbccc42&amp;id=8b3746988b&amp;f_id=00b62fe3f0"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_self"
    >
      <input type="hidden" name="u" value="eb05e4f830c2a04be30171b01" />
      <input type="hidden" name="id" value="8281a64779" />
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        p="4px 4px 4px 12px"
        gap={4}
        mx={{ base: 16, sm: 0 }}
        sx={(theme) => ({
          borderRadius: "50px",
          border: props.border ? "1px solid #E3E6E5" : "none",
          background: "linear-gradient(160.11deg, #FFFFFF 27.32%, #E3E6E5 106.23%)",
          boxShadow: "0px 8px 12px -2px rgba(0, 0, 0, 0.04), 0px 4px 6px -2px rgba(0, 0, 0, 0.04)",
          "& input": {
            [`@media(max-width: ${theme.breakpoints.sm})`]: {
              width: "150px",
            },
          },
        })}
      >
        <TextInput
          type="email"
          name="EMAIL"
          value={emailValue}
          onChange={(e) => setEmailValue(e.currentTarget.value)}
          autoCapitalize="off"
          autoCorrect="off"
          id="mce-EMAIL"
          size="md"
          w={{ base: "100%", sm: 260 }}
          required
          placeholder="Work Email Address"
          sx={{
            input: {
              background: "transparent",
              border: "1px solid transparent",
              "&:hover": { border: "1px solid transparent" },
            },
          }}
        />

        <Button
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
          size="lg"
          sx={{ borderRadius: "50px" }}
          variant="primary"
        >
          Subscribe
        </Button>
      </Flex>
      {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
      <div
        style={{ position: "absolute", left: "-5000px" }}
        aria-hidden="true"
        aria-label="Please leave the following three fields empty"
      >
        <div hidden>
          <input type="hidden" name="tags" value="3310281" readOnly />
        </div>

        <label htmlFor="b_name">Name: </label>
        <input
          type="text"
          name="b_name"
          tabIndex={-1}
          value=""
          readOnly
          placeholder="Freddie"
          id="b_name"
        />

        <label htmlFor="b_email">Email: </label>
        <input
          type="email"
          name="b_email"
          tabIndex={-1}
          value=""
          readOnly
          placeholder="youremail@gmail.com"
          id="b_email"
        />

        <label htmlFor="b_comment">Comment: </label>
        <textarea
          name="b_comment"
          tabIndex={-1}
          placeholder="Please comment"
          id="b_comment"
        ></textarea>
      </div>
    </form>
  );
}

import { useCallback, useState } from "react";

import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModel, ObjectPoolModelName, ObjectPoolEntityMap } from "utils/types";

import { MutationSettings } from "./settings";
import {
  getEntityMapFromQueryCache,
  replaceEntityMapInQueryCache,
  upsertRecordsInQueryCache,
  useBoundQueryClient,
} from "../queryCache";

export function useUpsertOneObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
  opts: MutationSettings = {},
) {
  const boundQueryClient = useBoundQueryClient();
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.upsertOne`);
  const upsertRecordMutation = trpc.useMutation(mutationPath);
  const { mutateAsync: _mutateAsync, mutate: _mutate, ...rest } = upsertRecordMutation;

  const mutate = useCallback(
    async (
      data: Partial<Omit<ObjectPoolModel<TModelName>, "id">>,
      ops: { optimistic: boolean } = { optimistic: true },
    ) => {
      const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);
      let modelSaveResultsArr = [] as ObjectPoolModel<TModelName>[];
      const shouldShowNavigationIndicator = opts.suppressUniversalLoader !== true;
      setIsMutating(true);
      try {
        // Optimistic
        if (ops.optimistic) {
          // @ts-expect-error TS2322
          upsertRecordsInQueryCache(boundQueryClient, modelName, [data]);
        }

        if (shouldShowNavigationIndicator) showUniversalLoader();
        const modelSaveResults = (await upsertRecordMutation.mutateAsync({
          data,
        })) as ObjectPoolEntityMap<TModelName>;
        // @ts-expect-error TS2322
        modelSaveResultsArr = ops.optimistic ? [data] : [modelSaveResults];

        // Resolve with actual save result
        upsertRecordsInQueryCache<TModelName>(boundQueryClient, modelName, modelSaveResultsArr);
      } catch (err) {
        console.error(err);
        replaceEntityMapInQueryCache<TModelName>(
          boundQueryClient,
          modelName,
          initialEntityMapFromCache,
        );
        throw err;
      }
      if (shouldShowNavigationIndicator) hideUniversalLoader();
      setIsMutating(false);
      return modelSaveResultsArr;
    },
    [boundQueryClient, modelName, opts.suppressUniversalLoader, upsertRecordMutation],
  );

  return { mutate, isMutating, ...rest };
}

export function generateBoundUseUpsertOneObjectPoolMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  function useBoundUpsertOneObjectPoolRecord(opts: MutationSettings = {}) {
    const useMutationResult = useUpsertOneObjectPoolRecordMutation<TModelName>(modelName, opts);
    return useMutationResult;
  }
  return useBoundUpsertOneObjectPoolRecord;
}

import { ExplorerSortKey, ExplorerSortDir, SortableFields } from "lib/frontend/benchmarking/types";
import _ from "lodash";

type Sortable =
  | (SortableFields & { companyName: string })
  | (SortableFields & { normalizedTitle: string })
  | (SortableFields & { title: string });

type SortableFn = (a: Sortable, b: Sortable) => number;
type ReversibleSortableFn = (a: Sortable, b: Sortable, reversed: boolean) => number;

function getTieBreaker(sortable: Sortable): string {
  if ("companyName" in sortable) {
    return _.toLower(sortable.companyName);
  }

  if ("normalizedTitle" in sortable) {
    return _.toLower(sortable.normalizedTitle);
  }

  if ("jobFamily" in sortable) {
    // @ts-expect-error TS2339
    return _.toLower(`${sortable.jobFamily} ${sortable.level}`);
  }

  return _.toLower(sortable.title);
}

export function getSortFunction(sortKey: ExplorerSortKey, sortDir: ExplorerSortDir): SortableFn {
  switch (sortKey) {
    case ExplorerSortKey.RANGE_HIGH:
      if (sortDir === ExplorerSortDir.ASC) {
        return generateCascadeSortFn([asc(sortSalaryHigh), asc(sortSalaryLow), sortAlphabetical]);
      } else {
        return generateCascadeSortFn([desc(sortSalaryHigh), desc(sortSalaryLow), sortAlphabetical]);
      }
    case ExplorerSortKey.RANGE_MID:
      if (sortDir === ExplorerSortDir.ASC) {
        return generateCascadeSortFn([asc(sortSalaryMid), sortAlphabetical]);
      } else {
        return generateCascadeSortFn([desc(sortSalaryMid), desc(sortSalaryLow), sortAlphabetical]);
      }
    case ExplorerSortKey.RANGE_LOW:
      if (sortDir === ExplorerSortDir.ASC) {
        return generateCascadeSortFn([asc(sortSalaryLow), asc(sortSalaryHigh), sortAlphabetical]);
      } else {
        return generateCascadeSortFn([desc(sortSalaryLow), desc(sortSalaryHigh), sortAlphabetical]);
      }
    case ExplorerSortKey.NUM_POSTS:
      if (sortDir === ExplorerSortDir.ASC) {
        return generateCascadeSortFn([asc(sortNumPosts), sortAlphabetical]);
      } else {
        return generateCascadeSortFn([desc(sortNumPosts), sortAlphabetical]);
      }
    case ExplorerSortKey.ALPHABETICAL:
      if (sortDir === ExplorerSortDir.ASC) {
        return generateCascadeSortFn([sortAlphabetical]);
      } else {
        return generateCascadeSortFn([desc(sortAlphabetical)]);
      }
    default:
      throw new Error(`Invalid sort key: ${sortKey}`);
  }
}

function sortSalaryLow(a: Sortable, b: Sortable, reverse: boolean): number {
  if (!a.salaryLow && !b.salaryLow) {
    return 0;
  }
  if (!a.salaryLow) {
    return 1;
  }
  if (!b.salaryLow) {
    return -1;
  }
  return (a.salaryLow - b.salaryLow) * (reverse ? -1 : 1);
}

function sortSalaryMid(a: Sortable, b: Sortable, reverse: boolean): number {
  if (!a.salaryMid && !b.salaryMid) {
    return 0;
  }
  if (!a.salaryMid) {
    return 1;
  }
  if (!b.salaryMid) {
    return -1;
  }
  return (a.salaryMid - b.salaryMid) * (reverse ? -1 : 1);
}

function sortSalaryHigh(a: Sortable, b: Sortable, reverse: boolean): number {
  if (!a.salaryHigh && !b.salaryHigh) {
    return 0;
  }
  if (!a.salaryHigh) {
    return 1;
  }
  if (!b.salaryHigh) {
    return -1;
  }
  return (a.salaryHigh - b.salaryHigh) * (reverse ? -1 : 1);
}

function sortNumPosts(a: Sortable, b: Sortable, reverse: boolean): number {
  if (!a.numPosts && !b.numPosts) {
    return 0;
  }
  if (!a.numPosts) {
    return 1;
  }
  if (!b.numPosts) {
    return -1;
  }

  return (a.numPosts - b.numPosts) * (reverse ? -1 : 1);
}

function sortAlphabetical(a: Sortable, b: Sortable, reverse: boolean): number {
  return sortTieBreaker(a, b, !reverse);
}

function sortTieBreaker(a: Sortable, b: Sortable, reverse: boolean): number {
  if (!reverse) {
    return getTieBreaker(a).localeCompare(getTieBreaker(b), "en-US");
  } else {
    return getTieBreaker(b).localeCompare(getTieBreaker(a), "en-US");
  }
}

function asc(sortFn: ReversibleSortableFn): SortableFn {
  return (a, b) => sortFn(a, b, false);
}

function desc(sortFn: ReversibleSortableFn): SortableFn {
  return (a, b) => sortFn(a, b, true);
}

function generateCascadeSortFn(sortFns: ReversibleSortableFn[]): SortableFn {
  return (a, b) => cascadeSort(a, b, sortFns);
}

function cascadeSort(a: Sortable, b: Sortable, sortFns: ReversibleSortableFn[]): number {
  for (const sortFn of sortFns) {
    const sortKey = sortFn(a, b, false);
    if (sortKey !== 0) {
      return sortKey;
    }
  }

  return 0;
}

export enum Regions {
  CA = "CA",
  NYC = "NYC",
  WA = "WA",
  CO = "CO",
  ROW = "ROW",
  US = "US",
  REMOTE = "REM",
}

export const REGION_ALL = "all";
export type RegionInputs = Regions;

export const DEFAULT_REGION_INPUTS: RegionInputs[] = [];

export enum Geographies {
  CA = "CA",
  SF = "SF",
  NY = "NY",
  NYC = "NYC",
  TX = "TX",
  WA = "WA",
  US = "US",
  CANADA = "CANADA",
  INTL = "INTL",
  REMOTE = "REMOTE",
}

import React from "react";

import type { TextProps } from "@mantine/core";
import { Text, useMantineTheme } from "@mantine/core";

import { mergeSx } from "ui/theme/helpers";

export const TooltipTargetText = React.forwardRef<HTMLDivElement, TextProps>((rawProps, ref) => {
  const theme = useMantineTheme();
  const { sx, ...rest } = mergeSx(rawProps, {
    cursor: "pointer",
    borderBottom: `1px dashed ${theme.colors.yellow[9]}`,
  });
  return <Text sx={sx} {...rest} ref={ref} />;
});
TooltipTargetText.displayName = "TooltipTargetText";

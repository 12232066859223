import { Notification } from "@mantine/core";

export const notificationComponentSettings = Notification.extend({
  styles: () => {
    return {
      root: {
        padding: 16,
        // Extra padding to account for the accent bar they provide
        paddingLeft: 24,
      },
      icon: {
        marginLeft: 14,
        marginRight: 16,
      },
    };
  },
});

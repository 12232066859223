import { useState } from "react";

import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { OpTransaction, createOpTransaction } from "lib/shared/object-pool";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModel, ObjectPoolModelName, ObjectPoolModelUpdateArgs } from "utils/types";

import { MutationSettings } from "./settings";
import { getRecordFromQueryCache, useBoundQueryClient } from "../queryCache";
import { useResolveObjectPoolTransactions } from "../useTransactionalMutation";

type UpdateObjectPoolRecordMutationResult<TModelName extends ObjectPoolModelName> = {
  transactions: Array<OpTransaction>;
  meta: {
    updatedRecord: ObjectPoolModel<TModelName>;
  };
};

export type UpdateObjectPoolMutationOps = { optimistic?: boolean };

export function useUpdateObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
  opts: MutationSettings = {},
) {
  const boundQueryClient = useBoundQueryClient();
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const { companyId } = boundQueryClient;
  // TODO: Rename this.
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.updateOne`);
  const updateRecordMutation = trpc.useMutation(mutationPath);
  const resolveObjectPoolTransactions = useResolveObjectPoolTransactions();

  async function mutate(
    // This is a hack because I guess right now we can't rely on the
    // id being in the type? I don't know we'll get there.
    id: ObjectPoolModel<TModelName> extends { id: string | number }
      ? ObjectPoolModel<TModelName>["id"]
      : string | number,

    _updateArgs: ObjectPoolModelUpdateArgs<TModelName>,
    ops: UpdateObjectPoolMutationOps = { optimistic: true },
  ) {
    // @ts-expect-error TS2345
    const updateArgs: ObjectPoolModelUpdateArgs<TModelName> = _.omit(_updateArgs, "explicitKind");
    const initialRecordValueFromCache = getRecordFromQueryCache(boundQueryClient, modelName, id);
    if (!initialRecordValueFromCache) {
      throw Error("Object Pool: Could not find record to update");
    }
    const shouldShowNavigationIndicator = opts.suppressUniversalLoader !== true;
    setIsMutating(true);

    const optimisticUpdateTransaction = createOpTransaction(modelName, "UPDATE", {
      // @ts-expect-error TS2322
      where: { id },
      data: updateArgs,
    });

    // Optimistic
    if (ops.optimistic) {
      resolveObjectPoolTransactions([optimisticUpdateTransaction]);
    }

    if (shouldShowNavigationIndicator) showUniversalLoader();
    let modelSaveResult = null;
    try {
      const data = {
        ...updateArgs,
        // Note that, for the time being we require companyId (see notes
        // on the leaky abstraction above) so we ensure it gets added
        // here.
        ...(modelName === "Company" ? {} : { companyId }),
      } as Partial<ObjectPoolModelUpdateArgs<TModelName>>;
      const response = (await updateRecordMutation.mutateAsync({
        where: { id },
        data,
      })) as UpdateObjectPoolRecordMutationResult<TModelName>;

      // Resolve with actual save result
      resolveObjectPoolTransactions(response.transactions);
    } catch (err) {
      console.error(err);
      const revertUpdateTransaction = createOpTransaction(modelName, "UPDATE", {
        // @ts-expect-error TS2322
        where: { id },
        data: initialRecordValueFromCache,
      });
      resolveObjectPoolTransactions([revertUpdateTransaction]);
      throw err;
    }
    if (shouldShowNavigationIndicator) hideUniversalLoader();
    setIsMutating(false);
    return modelSaveResult;
  }

  return { mutate, isMutating };
}

export function generateBoundUseUpdateObjectPoolMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  function useBoundUpdateObjectPoolRecord(opts: MutationSettings = {}) {
    const useMutationResult = useUpdateObjectPoolRecordMutation<TModelName>(modelName, opts);
    return useMutationResult;
  }
  return useBoundUpdateObjectPoolRecord;
}

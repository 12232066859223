import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModelName, ObjectPoolModel } from "utils/types";

import { MutationSettings } from "./settings";
import { addRecordToQueryCache, useBoundQueryClient } from "../queryCache";

type CreateType<T> = Omit<T, "id">;

export function useCreateObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
  opts: MutationSettings = {},
) {
  const boundQueryClient = useBoundQueryClient();
  const { companyId } = boundQueryClient;
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.createOne`);
  const createRecordMutation = trpc.useMutation(mutationPath);

  async function mutate(createArgs: CreateType<ObjectPoolModel<TModelName>>) {
    // Note we can't do an optimisitc update on create - we have to wait
    // for the creation to complete so we have an ID.
    const shouldShowNavigationIndicator = opts.suppressUniversalLoader !== true;
    if (shouldShowNavigationIndicator) showUniversalLoader();
    let modelSaveResult = null;
    try {
      modelSaveResult = (await createRecordMutation.mutateAsync({
        // Note that, for the time being we require companyId (see notes
        // on the leaky abstraction above) so we ensure it gets added
        // here.
        data: { ...createArgs, companyId } as ObjectPoolModel<TModelName>,
      })) as ObjectPoolModel<TModelName>;

      // Resolve with actual save result
      addRecordToQueryCache<TModelName>(boundQueryClient, modelName, modelSaveResult);
    } catch (err) {
      // There was no optimistic update, so nothing to unwind.
      console.error(err);
      throw err;
    }
    if (shouldShowNavigationIndicator) hideUniversalLoader();
    return modelSaveResult;
  }

  return { mutate, isMutating: createRecordMutation.isLoading };
}

export function generateBoundUseCreateObjectPoolMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  function useBoundCreateObjectPoolRecord(opts: MutationSettings = {}) {
    const useMutationResult = useCreateObjectPoolRecordMutation<TModelName>(modelName, opts);
    return useMutationResult;
  }
  return useBoundCreateObjectPoolRecord;
}

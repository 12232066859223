import { Tabs } from "@mantine/core";

import classNames from "./Tabs.module.css";

export const tabsComponentSettings = Tabs.extend({
  defaultProps: {
    color: "bay.5",
  },
  classNames,
});

import { NavLink } from "@mantine/core";

export const navLinkComponentSettings = NavLink.extend({
  styles: (theme) => ({
    icon: {
      color: theme.colors.gray[4],
      ".active &": {
        color: theme.colors.bay[7],
      },
    },
    label: {
      color: theme.colors.gray[5],
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      ".active &": {
        color: theme.colors.bay[7],
      },
    },
    root: {
      height: "40px",
      padding: "8px 16px",
      borderRadius: theme.radius.sm,
      "&.active, &:hover": {
        backgroundColor: theme.colors.gray[2],
      },
    },
    children: {
      ".mantine-NavLink-root": {
        margin: "4px 0",
      },
      ".mantine-NavLink-label": {
        margin: "0 1rem",
      },
    },
  }),
});

import { Modal } from "@mantine/core";

import { headingsThemeSettings } from "../Title";

const { sizes, ...rest } = headingsThemeSettings;

export const modalComponentSettings = Modal.extend({
  defaultProps: {
    centered: true,
    padding: 0,
  },
  styles: (theme) => {
    return {
      body: {
        padding: 24,
        // Little hacky - this isn't codified in the design system but
        // conventionally, the last item in a Modal is a group with
        // buttons in it, so we add some space.
        // (Note that this space is only added if it's not the only element
        // in the modal, in which case it's unnecessary.
        "& > .mantine-Group-root:last-child:not(:first-child)": {
          marginTop: "var(--mantine-spacing-xl)",
        },
      },
      header: {
        paddingTop: 24,
        paddingLeft: 24,
        paddingRight: 24,
        // borderBottom: `1px solid ${theme.colors.gray[3]}`,
        marginRight: 0,
        marginBottom: 0,
        alignItems: "flex-start",
      },
      title: {
        ...rest,
        ...sizes.h4,
      },
    };
  },
});

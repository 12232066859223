import { Radio } from "@mantine/core";

import { formSelectionGroup } from "../../tokens";

export const radioComponentSettings = Radio.extend({
  defaultProps: {
    color: "bay.8",
  },
  styles: {
    label: {
      paddingLeft: formSelectionGroup.labelSpacing(),
    },
    description: {
      paddingLeft: formSelectionGroup.descriptionSpacing(),
    },
  },
});

import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { getMutationPath, trpc } from "utils/trpc";
import { ObjectPoolModelName, ObjectPoolModel } from "utils/types";

import { addRecordsToQueryCache, useBoundQueryClient } from "../queryCache";

export function useCreateManyObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const { companyId } = boundQueryClient;
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.createMany`);
  const createRecordMutation = trpc.useMutation(mutationPath);

  async function mutate(createManyArgs: Omit<ObjectPoolModel<TModelName>, "id">[]) {
    // Note we can't do an optimisitc update on create - we have to wait
    // for the creation to complete so we have an ID.

    showUniversalLoader();
    let modelsSaveResult = null;
    try {
      const safeCreateManyArgs = createManyArgs.map((entry) => ({ ...entry, companyId }));
      modelsSaveResult = (await createRecordMutation.mutateAsync({
        // Note that, for the time being we require companyId (see notes
        // on the leaky abstraction above) so we ensure it gets added
        // here.
        data: safeCreateManyArgs as ObjectPoolModel<TModelName>[],
      })) as ObjectPoolModel<TModelName>[];

      // Resolve with actual save result
      addRecordsToQueryCache<TModelName>(boundQueryClient, modelName, modelsSaveResult);
    } catch (err) {
      // There was no optimistic update, so nothing to unwind.
      console.error(err);
      throw err;
    }
    hideUniversalLoader();
    return modelsSaveResult;
  }

  return { mutate };
}

export function generateBoundUseCreateManyObjectPoolMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBoundCreateManyObjectPoolRecord() {
    const useMutationResult = useCreateManyObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBoundCreateManyObjectPoolRecord;
}

import { User, Role } from "prisma/cm/client";

export function isCompanyAdmin<T extends { role: Role }>(user: T | null): boolean {
  if (!user) return false;
  return user.role === Role.COMPANY_ADMIN;
}
/*
 * Indicates internal Comprehensive admins who have access
 * to additional internal tooling. We distinguish between these
 * users and "Company Admins" who have access to administer their
 * own company.
 */
export function isSuperUser(user: User | null): boolean {
  if (!user) return false;
  return user.isSuperUser;
}

export const isComprehensiveInternalUser = (user: User | null): boolean => {
  return Boolean(user?.worksForComprehensive);
};

import React from "react";

import { useBind, ServerBindingContextValue } from "./useBind";

export function ServerBindingProvider({ children }: { children?: React.ReactNode }) {
  const hookValues = useBind();

  return (
    <ServerBindingContext.Provider value={hookValues}>{children}</ServerBindingContext.Provider>
  );
}

const ServerBindingContext = React.createContext<ServerBindingContextValue | null>(null);

export function useServerBinding() {
  const context = React.useContext(ServerBindingContext);
  if (!context) {
    throw new Error("useServerBinding must be used within a ServerBindingProvider");
  }
  return context;
}

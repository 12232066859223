import { Regions } from "./regions";

export const STAGE_OPTIONS = [
  "Post-IPO",
  "Series Unknown", // "Venture - Series Unknown"
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E", // (this would filter on Series E, Series F, .., through Series J) (handled in code)
  "Pre-Seed/Seed", //  (this would filter on Pre-Seed, Seed, and Angel)
  "Other", // (this would filter on everything else)"
] as const;

// this is reflected in the db now
// export function mapStageSelectOptionToDbValues(option: typeof STAGE_OPTIONS[number]): string[] {
//   switch(option) {
//     case "Pre-Seed/Seed":
//       return ["Pre-Seed", "Seed", "Angel"];
//     case "Series E+":
//       return ["E", "F", "G", "H", "I", "J"].map((letter) => `Series ${letter}`);
//     case "Series A":
//     case "Series B":
//     case "Series C":
//     case "Series D":
//       return [option];
//     case "Post-IPO":
//       return ["Post-IPO Equity"];
//     case "Series Unknown":
//       return ["Venture - Series Unknown"];
//     case "Other":
//       return [
//         "Undisclosed",
//         "Corporate Round",
//         "Initial Coin Offering",
//         "Private Equity",
//         "Equity Crowdfunding",
//         "Corporate Round",
//       ];
//     default:
//       return [];
//   }
// }

export const INDUSTRY_OPTIONS = [
  "Administrative Services",
  "Advertising",
  "Agriculture and Farming",
  "Apps",
  "Artificial Intelligence",
  "Biotechnology",
  "Clothing and Apparel",
  "Commerce and Shopping",
  "Community and Lifestyle",
  "Consumer Electronics",
  "Consumer Goods",
  "Content and Publishing",
  "Data and Analytics",
  "Design",
  "Education",
  "Energy",
  "Events",
  "Financial Services",
  "Food and Beverage",
  "Gaming",
  "Government and Military",
  "Hardware",
  "Health Care",
  "Information Technology",
  "Internet Services",
  "Lending and Investments",
  "Manufacturing",
  "Media and Entertainment",
  "Messaging and Telecommunications",
  "Mobile",
  "Music and Audio",
  "Natural Resources",
  "Navigation and Mapping",
  "Other",
  "Payments",
  "Platforms",
  "Privacy and Security",
  "Professional Services",
  "Real Estate",
  "Sales and Marketing",
  "Science and Engineering",
  "Software",
  "Sports",
  "Sustainability",
  "Transportation",
  "Travel and Tourism",
  "Video",
];

export const LOCATION_OPTIONS = [
  {
    label: "California",
    value: Regions.CA,
  },
  {
    label: "Colorado",
    value: Regions.CO,
  },
  {
    label: "New York City",
    value: Regions.NYC,
  },
  {
    label: "Remote",
    value: Regions.REMOTE,
  },
  {
    label: "Washington",
    value: Regions.WA,
  },
  {
    label: "Other U.S.",
    value: Regions.US,
  },
  {
    label: "International",
    value: Regions.ROW,
  },
];

export const TENK_PLUS = "10,001plus";

export const HEADCOUNT_OPTIONS = [
  { label: "51-100", value: "51-100" },
  { label: "101-250", value: "101-250" },
  { label: "251-500", value: "251-500" },
  { label: "501-1,000", value: "501-1,000" },
  { label: "1,001-5,000", value: "1,001-5,000" },
  { label: "5,001-10,000", value: "5,001-10,000" },
  { label: "10,001+", value: TENK_PLUS },
];

export enum YOE_OPTIONS {
  ZERO_ONE = "0-1",
  TWO_FOUR = "2-4",
  FIVE_SEVEN = "5-7",
  EIGHT_ELEVEN = "8-11",
  TWELVE_PLUS = "12plus",
  UNSPECIFIED = "Unspecified",
}

export const YOE_OPTIONS_ARR = [
  { label: YOE_OPTIONS.ZERO_ONE, value: YOE_OPTIONS.ZERO_ONE },
  { label: YOE_OPTIONS.TWO_FOUR, value: YOE_OPTIONS.TWO_FOUR },
  { label: YOE_OPTIONS.FIVE_SEVEN, value: YOE_OPTIONS.FIVE_SEVEN },
  { label: YOE_OPTIONS.EIGHT_ELEVEN, value: YOE_OPTIONS.EIGHT_ELEVEN },
  { label: "12+", value: YOE_OPTIONS.TWELVE_PLUS },
  { label: YOE_OPTIONS.UNSPECIFIED, value: YOE_OPTIONS.UNSPECIFIED },
];

/**
 * quick and dirty way to get around plus being hard for URLSearchParams to decode
 */
export function fixPlusUrlParamVals(value: string): string {
  if (value === TENK_PLUS) {
    return "10,001+";
  }

  if (value === YOE_OPTIONS.TWELVE_PLUS) {
    return "12+";
  }

  return value;
}

export const EXPLORER_PAGE_SIZE = 20;

export const NOTHING_FOUND = "No results for this search";

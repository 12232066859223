import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModelName, ObjectPoolModel } from "utils/types";

import {
  deleteRecordFromQueryCache,
  getEntityMapFromQueryCache,
  replaceEntityMapInQueryCache,
  useBoundQueryClient,
} from "../queryCache";

export function useDeleteByIdObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const { companyId } = boundQueryClient;
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.delete`);
  const deleteRecordMutation = trpc.useMutation(mutationPath);

  async function mutate(id: ObjectPoolModel<TModelName>["id"]) {
    const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);
    if (!initialEntityMapFromCache) {
      throw Error("Object Pool: Could not find entity map to cache");
    }

    // Optimistic
    deleteRecordFromQueryCache(boundQueryClient, modelName, id);

    showUniversalLoader();
    let modelSaveResult = null;
    try {
      modelSaveResult = (await deleteRecordMutation.mutateAsync({
        where: { id, companyId },
      })) as ObjectPoolModel<TModelName>;

      // No resolution necessary with actual save result.
    } catch (err) {
      console.error(err);
      replaceEntityMapInQueryCache<TModelName>(
        boundQueryClient,
        modelName,
        initialEntityMapFromCache,
      );
      throw err;
    }
    hideUniversalLoader();
    return modelSaveResult;
  }

  return { mutate };
}

export function generateBoundUseDeleteByIdObjectPoolMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBoundDeleteByIdObjectPoolRecord() {
    const useMutationResult = useDeleteByIdObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBoundDeleteByIdObjectPoolRecord;
}

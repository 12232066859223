import { EmailAddress, User } from "prisma/cm/client";

type UserWithEmails = User & { emails: EmailAddress[] };

export function getPrimaryEmail(user: UserWithEmails): string | null {
  return user.emails.find((ea) => ea.isPrimary)?.email || null;
}

export function getDisplayEmail(user: UserWithEmails): string | null {
  return user.emails.length > 1 ? getPrimaryEmail(user) : user.emails[0]?.email || null;
}

import { createReactComponent } from "@tabler/icons-react";

// attrs manually copied from icon-layout-list-offset.svg

export const IconLayoutListOffset = createReactComponent(
  "outline",
  "layout-list-offset",
  "IconLayoutListOffset",
  [
    [
      "path",
      {
        d: "M4 6C4 5.46957 4.18437 4.96086 4.51256 4.58579C4.84075 4.21071 5.28587 4 5.75 4H16.25C16.7141 4 17.1592 4.21071 17.4874 4.58579C17.8156 4.96086 18 5.46957 18 6V8C18 8.53043 17.8156 9.03914 17.4874 9.41421C17.1592 9.78929 16.7141 10 16.25 10H5.75C5.28587 10 4.84075 9.78929 4.51256 9.41421C4.18437 9.03914 4 8.53043 4 8V6Z",
        key: "svg-0",
      },
    ],
    [
      "path",
      {
        d: "M6 16C6 15.4696 6.18437 14.9609 6.51256 14.5858C6.84075 14.2107 7.28587 14 7.75 14H18.25C18.7141 14 19.1592 14.2107 19.4874 14.5858C19.8156 14.9609 20 15.4696 20 16V18C20 18.5304 19.8156 19.0391 19.4874 19.4142C19.1592 19.7893 18.7141 20 18.25 20H7.75C7.28587 20 6.84075 19.7893 6.51256 19.4142C6.18437 19.0391 6 18.5304 6 18V16Z",
        key: "svg-1",
      },
    ],
  ],
);

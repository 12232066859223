import { Menu } from "@mantine/core";

export const menuComponentSettings = Menu.extend({
  styles: (theme) => {
    return {
      item: {
        padding: 14,
        fontWeight: theme.other.fontWeights.medium,
      },
      dropdown: {
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.lg,
        minWidth: 240,
      },
      itemSection: {
        [`&[data-position="left"]`]: {
          marginRight: theme.spacing.md,
        },
        [`&[data-position="right"]`]: {
          marginLeft: theme.spacing.md,
        },
      },
    };
  },
});

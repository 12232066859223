import { createContext, useContext } from "react";

import { CmTrpcReactClient } from "./createTrpcReactClient";

type TrpcClientContextValue = CmTrpcReactClient;

export const TrpcClientContext = createContext<TrpcClientContextValue>(
  {} as TrpcClientContextValue,
);

export function useTrpcClient() {
  return useContext(TrpcClientContext);
}

export function TrpcClientProvider({
  client,
  children,
}: {
  client: CmTrpcReactClient;
  children: React.ReactNode;
}) {
  return <TrpcClientContext.Provider value={client}>{children}</TrpcClientContext.Provider>;
}

import _ from "lodash";

export type TQuerySelectorAllAsyncOps = { intervalDuration: number; timeoutDuration: number };
const defaultQuerySelectorAllAsyncOps: TQuerySelectorAllAsyncOps = {
  intervalDuration: 250,
  timeoutDuration: 5000,
};

export async function querySelectorAllAsync(
  selector: string,
  rawOps: Partial<TQuerySelectorAllAsyncOps> = {},
): Promise<NodeListOf<Element>> {
  return new Promise((resolve, reject) => {
    const ops: TQuerySelectorAllAsyncOps = _.defaults({}, rawOps, defaultQuerySelectorAllAsyncOps);

    let elementsList = document.querySelectorAll(selector);
    if (elementsList.length > 0) return resolve(elementsList);
    let totalDuration = 0;

    // In case the element isn't yet present, wait for it.
    const interval = window.setInterval(() => {
      totalDuration += ops.intervalDuration;
      elementsList = document.querySelectorAll(selector);
      if (elementsList.length > 0) {
        clearInterval(interval);
        resolve(elementsList);
      }
      if (totalDuration >= ops.timeoutDuration) {
        clearInterval(interval);
        reject("Could not find element");
      }
    }, ops.intervalDuration);
  });
}

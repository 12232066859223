import { createAction, createReducer } from "@reduxjs/toolkit";

import { LocalCurrency, Review, UserWithCompany } from "frontend/utils/types";
import { User } from "prisma/cm/client";
import { US_DOLLAR } from "utils/constants";

export type CurrencyState = LocalCurrency | string;

export interface CoreState {
  user: UserWithCompany | null;
  impersonatorUser: User | null;
  viewMetadata: Record<string, any> | null;
  isGuest: boolean;
  review: Review | null;
  isGoogleOneTapVisible: boolean;
  redirectTo: string;
  logo: string;
  currency: CurrencyState | null;
}

const initialState: CoreState = {
  user: null,
  impersonatorUser: null,
  viewMetadata: null,
  isGuest: true,
  review: null,
  isGoogleOneTapVisible: false,
  redirectTo: "/",
  logo: "",
  currency: null,
};

export const loadUser = createAction<CoreState["user"]>("user/load");
export const loadImpersonatorUser =
  createAction<CoreState["impersonatorUser"]>("user/loadImpersonator");
export const loadViewMetadata = createAction<CoreState["viewMetadata"]>("user/loadViewMetadata");
export const setCurrency = createAction<CoreState["currency"]>("user/setCurrency");
export const reset = createAction("user/reset");
export const setPageSize = createAction<number>("user/pagination-page-size");
export const updateOneTapVisibility =
  createAction<CoreState["isGoogleOneTapVisible"]>("user/oneTap");
export const loadReview = createAction<CoreState["review"]>("review/load");
export const setRedirectTo = createAction<CoreState["redirectTo"]>("/user/redirect-to");
export const setLogo = createAction<CoreState["logo"]>("/company/logo");

/*
 * This selector is pretty bloated as it assumes the context of a review or an
 * authenticated user as being relevant to the context. We should probably
 * deprecate this.
 *
 * Instead, prefer the leaner currency selector below.
 */
export const userOrReviewContextCurrencySelector = (store: { core: CoreState }) =>
  store.core.currency ??
  store.core.user?.defaultCurrency ??
  store.core.review?.defaultCurrency ??
  store.core.user?.company?.defaultCurrency ??
  US_DOLLAR;

export const selectedCurrencySelector = (store: { core: CoreState }) =>
  store.core.currency ??
  store.core.user?.defaultCurrency ??
  store.core.user?.company?.defaultCurrency ??
  US_DOLLAR;

const coreReducer = createReducer(initialState, (builder) => {
  builder.addCase(loadUser, (state, action) => {
    state.user = action.payload;
    state.isGuest = !!state.user;
  });
  builder.addCase(loadImpersonatorUser, (state, action) => {
    state.impersonatorUser = action.payload;
  });
  builder.addCase(loadViewMetadata, (state, action) => {
    state.viewMetadata = action.payload;
  });
  builder.addCase(setCurrency, (state, action) => {
    state.currency = action.payload;
  });
  builder.addCase(updateOneTapVisibility, (state, action) => {
    state.isGoogleOneTapVisible = action.payload;
  });
  builder.addCase(loadReview, (state, action) => {
    const review = action.payload;
    state.review = review;
  });
  builder.addCase(setRedirectTo, (state, action) => {
    state.redirectTo = action.payload;
  });
  builder.addCase(reset, ({ redirectTo }) => ({ ...initialState, redirectTo }));
  builder.addCase(setLogo, (state, action) => {
    state.logo = action.payload;
  });

  builder.addCase(setPageSize, (state, action) => {
    if (state.user) state.user.paginationPageSize = action.payload;
  });
});

export default coreReducer;

import _ from "lodash";

function removeParens(str: string) {
  return str.replace(/\([^()]*\)/g, "");
}

export function getInitials(name: string) {
  const tokens = removeParens(name ?? "")
    .replace(/\s+/g, " ")
    .trim()
    .split(" ");
  const firstToken = _.first(tokens) || "";
  const lastToken = (tokens.length > 1 && _.last(tokens)) || "";
  const firstInit = firstToken[0];
  const lastInit = lastToken[0];
  return [firstInit, lastInit].join("").toUpperCase();
}

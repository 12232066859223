export * from "./createTrpcReactClient";
export * from "./TrpcClientContext";

import { trpc } from "./createTrpcReactClient";

// TODO validate these paths rather than blindly casting them
type QueryPath = Parameters<typeof trpc.useQuery>[0];
export function getQueryPath(path: string): QueryPath {
  return path as unknown as QueryPath;
}

type MutationPath = Parameters<typeof trpc.useMutation>[0];
export function getMutationPath(path: string): MutationPath {
  return path as unknown as MutationPath;
}

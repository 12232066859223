import {
  IconRefresh,
  IconTargetArrow,
  IconUsers,
  IconListCheck,
  IconCashBanknote,
} from "@tabler/icons-react";

import { IconLayoutListOffset } from "./custom/IconLayoutListOffset";

export const FeatureIcon = {
  GettingStarted: IconListCheck,
  Employees: IconUsers,
  Ranges: IconLayoutListOffset,
  Cycles: IconRefresh,
  Benchmarking: IconTargetArrow,
  TotalRewards: IconCashBanknote,
};

import { ReactElement } from "react";

import { PageContentBox } from "./PageContentBox";
import { TrackerAppShell } from "./TrackerAppShell";

export function getDefaultLayout(page: ReactElement) {
  return (
    <TrackerAppShell>
      <PageContentBox>{page}</PageContentBox>
    </TrackerAppShell>
  );
}

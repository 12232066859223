import { InputWrapper } from "@mantine/core";

export const inputWrapperComponentSettings = InputWrapper.extend({
  styles: (theme) => ({
    label: {
      marginBottom: theme.spacing.sm,
    },
    error: {
      marginTop: theme.spacing.sm,
    },
  }),
});

import { SegmentedControl } from "@mantine/core";
import classes from "./SegmentedControl.module.css";

export const segmentedControlComponentSettings = SegmentedControl.extend({
  defaultProps: {
    // TODO: there seems to be a few janky ui issues with SegmentedControls
    // including: https://github.com/mantinedev/mantine/issues/2454
    // 'removeing' the animation for now
    transitionDuration: 0,
  },
  classNames: classes,
});

import type { BoxProps } from "@mantine/core";
import { useMantineTheme, MantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import _ from "lodash";

// TODO: Move this.
export const getPrimaryColor = (theme: MantineTheme) => {
  const { primaryColor } = theme;
  return theme.colors[primaryColor];
};

export const usePrimaryColor = () => {
  const theme = useMantineTheme();
  return getPrimaryColor(theme);
};

export const usePrimaryColorShade = () => {
  const { colors, primaryColor, primaryShade } = useMantineTheme();
  // @ts-expect-error TS2538
  return colors[primaryColor][primaryShade];
};

export function useIsLargerThan(breakpointSize: "xs" | "sm" | "md" | "lg" | "xl") {
  const { breakpoints } = useMantineTheme();
  return useMediaQuery(`(min-width: ${breakpoints[breakpointSize]})`);
}

export function useIsSmallerThan(breakpointSize: "xs" | "sm" | "md" | "lg" | "xl") {
  const { breakpoints } = useMantineTheme();
  return useMediaQuery(`(max-width: ${breakpoints[breakpointSize]})`);
}

/*
 * Helper fn to handle merging of sx props so that
 * they can be easily handed from parent to child without
 * overwrites.
 */
export type PropsWithSx = Pick<BoxProps, "sx">;
type Sx = BoxProps["sx"];
export const mergeSx = <TProps extends PropsWithSx = PropsWithSx>(
  rawProps: TProps,
  sxOverrides: Sx = {},
) => {
  const { sx: rawSx = {}, ...rest } = rawProps;
  const sx: Sx = _.merge({}, sxOverrides, rawSx);
  const props = { sx, ...rest } as TProps;
  return props;
};

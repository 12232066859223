import {
  TitleExplorerCompanyRollup,
  TitleExplorerResult,
  PostWithCompany,
  PostWithSlimCompany,
  ExplorerSortDir,
  ExplorerSortKey,
} from "lib/frontend/benchmarking/types";
import _ from "lodash";

import { getSortFunction } from "./sorting";
import { medianBy } from "./utils";

export const MAX_TITLE_ROLLUPS_TO_RENDER = 50;

export function processPostsForTitlesExplore(posts: PostWithCompany[]): TitleExplorerResult[] {
  const sortFn = getSortFunction(ExplorerSortKey.RANGE_HIGH, ExplorerSortDir.DESC);
  const titlePosts = _(posts)
    .groupBy("normalizedTitle")
    .keyBy((g) => g[0].normalizedTitle)
    .valueOf();

  const resultsAsTitleDict = _.mapValues(titlePosts, (titlePostSet, normalizedTitle) => {
    const postsAsCompanyDict = _.groupBy(titlePostSet, (r) => r.company.id);

    const companyRollups = _(postsAsCompanyDict)
      .mapValues((companyPosts) => {
        const slimCompanyPosts: PostWithSlimCompany[] = _.map(companyPosts, toSlimCompany);
        const orderedPosts = slimCompanyPosts.sort(sortFn);
        const companyPostsWithRanges = _.filter(companyPosts, "salaryLow");

        const companyRollup: TitleExplorerCompanyRollup = {
          company: companyPosts[0].company,
          companyId: companyPosts[0].company.id,
          companyName: companyPosts[0].company.name ?? "unknown",

          salaryLow: medianBy(companyPostsWithRanges, "salaryLow"),
          salaryMid: medianBy(companyPostsWithRanges, "salaryMid"),
          salaryHigh: medianBy(companyPostsWithRanges, "salaryHigh"),

          numPosts: orderedPosts.length,

          posts: orderedPosts,
        };

        return companyRollup;
      })
      .valueOf();

    const sortedCompanyRollups = _.values(companyRollups).sort(sortFn);
    const companyRollupsWithRanges = _.filter(sortedCompanyRollups, (r) => !_.isNil(r.salaryHigh));

    return {
      normalizedTitle,
      salaryLow: medianBy(companyRollupsWithRanges, "salaryLow"),
      salaryMid: medianBy(companyRollupsWithRanges, "salaryMid"),
      salaryHigh: medianBy(companyRollupsWithRanges, "salaryHigh"),

      companies: sortedCompanyRollups,
      numCompanies: sortedCompanyRollups.length,
      numPosts: _.sumBy(sortedCompanyRollups, "numPosts"),
    };
  });

  const results = _.values(resultsAsTitleDict).sort(sortFn);

  return results;
}

function toSlimCompany(r: PostWithCompany): PostWithSlimCompany {
  return {
    ...r,
    company: _.pick(r.company, ["id", "name", "logoUrl"]),
  };
}

import { MultiSelect } from "@mantine/core";

import { textInputComponentSettings } from "../TextInput";

export const multiSelectComponentSettings = MultiSelect.extend({
  styles: (theme) => {
    const { input } = textInputComponentSettings.styles(theme);
    return {
      input: {
        ...input,
        paddingTop: 2,
        paddingBottom: 2,
      },
      value: {
        fontSize: 14,
        fontWeight: theme.other.fontWeights.regular,
        backgroundColor: theme.colors.gray[2],
        borderRadius: 1000,
        height: "auto",
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 12,
        paddingRight: 4,
        marginTop: 2,
        marginBottom: 2,
      },
      values: {
        minHeight: 30,
      },
      defaultValueRemove: {
        color: theme.colors.gray[5],
      },
      searchInput: {
        lineHeight: "26px",
      },
      item: {
        padding: 6,
      },
    };
  },
});

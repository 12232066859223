/*
 * TODO: All of this is exposed via `theme.other`, so we should get rid
 * of this import here and prefer use of `useMantineTheme()`
 */

import { MantineTheme } from "@mantine/core";

export const fontStacks = {
  inter: "Inter, sans-serif",
};

export const fontWeights = {
  semiBold: 600,
  medium: 500,
  regular: 400,
};

export const formSelectionGroup = {
  horizontalSpacing: (_theme?: MantineTheme) => 32,
  verticalSpacing: (theme: MantineTheme) => theme.spacing.md,
  labelSpacing: (_theme?: MantineTheme) => 12,
  descriptionSpacing: (_theme?: MantineTheme) => 12,
};

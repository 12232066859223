export const descriptions = {
  IC1: {
    title: "Entry",
    description: "Contributes to smaller projects under detailed guidance.",
    YoE: "0-1 YOE",
  },
  IC2: {
    title: "Developing",
    description:
      "Contributes to projects under general guidance. Skills are relatively developed with room for growth.",
    YoE: "2-4 YOE",
  },
  IC3: {
    title: "Proficient",
    description:
      "Owns specific projects and requires minimal guidance. Skills are fully developed.",
    YoE: "5-7 YOE",
  },
  IC4: {
    title: "Advanced",
    description:
      "Owns complex projects and drives their direction. Displays in-depth and broad skills and expertise.",
    YoE: "8-11 YOE",
  },
  IC5: {
    title: "Expert",
    description:
      "A very experienced team member who leads large, high-priority projects that impact company-level goals.",
    YoE: "12+ YOE",
  },
  IC6: {
    title: "Advisory",
    description:
      "An authority in their field who defines and drives initiatives with long-term strategic impact.",
  },
  M3: {
    title: "Manager",
    description:
      "Directly supervises a team of individual contributors and is accountable for their results.",
  },
  M4: {
    title: "Sr. Manager",
    description:
      "Experienced people manager responsible for setting objectives, observing budgets, and tracking results.",
  },
  M5: {
    title: "Director",
    description:
      "Oversees managers or leads their own department. Responsible for vision, strategy, hiring, and results.",
  },
  M6: {
    title: "Sr. Director",
    description: "Manages senior leaders in various groups. Responsible for a business function.",
  },
};

import { EnvUtils } from "@/utils/EnvUtils";

import { useSafeCrossAppUser } from "./useSafeCrossAppUser";
import { useSafeCrossAppViewMetadata } from "./useSafeCrossAppViewMetadata";

export function useReturnToFifteenFiveUrl() {
  const user = useSafeCrossAppUser();
  const viewMetadata = useSafeCrossAppViewMetadata();

  if (!user?.company?.isFifteenFivePartner) {
    return null;
  }

  // @ts-expect-error TS7053
  const subdomain = viewMetadata["fifteenFiveCompanySubdomain"];

  if (EnvUtils.isProd) {
    return subdomain ? `https://${subdomain}.15five.com` : "https://my.15five.com";
  } else {
    return subdomain ? `https://${subdomain}.staging.15five.com` : "https://my.staging.15five.com";
  }
}

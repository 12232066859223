import { useState } from "react";

import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { trpc, getMutationPath } from "utils/trpc";
import {
  ObjectPoolModelBatchUpdateArgs,
  ObjectPoolModelName,
  ObjectPoolEntityMap,
} from "utils/types";

import {
  batchUpdateRecordsInQueryCache,
  getEntityMapFromQueryCache,
  replaceEntitiesInQueryCache,
  replaceEntityMapInQueryCache,
  useBoundQueryClient,
} from "../queryCache";

export function useBatchUpdateObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.batchUpdate`);
  const batchUpdateMutation = trpc.useMutation(mutationPath);
  const [isMutating, setIsMutating] = useState<boolean>(false);

  async function mutateAsync(
    updateArgs: ObjectPoolModelBatchUpdateArgs<TModelName>,
    ops: { optimistic: boolean } = { optimistic: true },
  ) {
    const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);
    setIsMutating(true);

    showUniversalLoader();
    let modelSaveResults = {} as ObjectPoolEntityMap<TModelName>;

    if (ops.optimistic) {
      batchUpdateRecordsInQueryCache(boundQueryClient, modelName, updateArgs);
    }

    try {
      modelSaveResults = (await batchUpdateMutation.mutateAsync(
        updateArgs,
      )) as ObjectPoolEntityMap<TModelName>;

      // Resolve with actual save result
      replaceEntitiesInQueryCache<TModelName>(boundQueryClient, modelName, modelSaveResults);
    } catch (err) {
      console.error(err);
      replaceEntityMapInQueryCache<TModelName>(
        boundQueryClient,
        modelName,
        initialEntityMapFromCache,
      );
      throw err;
    } finally {
      setIsMutating(false);
    }
    hideUniversalLoader();
    return modelSaveResults;
  }

  return {
    ...batchUpdateMutation,
    isMutating,
    mutateAsync,
  };
}

export function generateBoundBatchUpdateObjectPoolRecordMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBatchUpdateObjectPoolRecord() {
    const useMutationResult = useBatchUpdateObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBatchUpdateObjectPoolRecord;
}

import { createStyles } from "@mantine/emotion";
export const useStyles = createStyles((theme) => {
  return {
    pageContentBox: {
      maxWidth: 1600,
      margin: "0 auto",
      paddingLeft: 48,
      paddingRight: 48,
      width: "100%",

      [`@media(max-width: ${theme.breakpoints.md})`]: {
        maxWidth: "100%",
      },

      [`@media(max-width: ${theme.breakpoints.sm})`]: {
        paddingLeft: 16,
        paddingRight: 16,
      },

      [`@media(max-width: ${theme.breakpoints.xs})`]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    boxInternalPadding: {
      [`@media(max-width: ${theme.breakpoints.xs})`]: {
        padding: "0 16px",
      },
    },
    hideLargerThanSm: {
      [`@media(min-width: ${theme.breakpoints.sm})`]: {
        display: "none",
      },
    },
    hideSmallerThanSm: {
      [`@media(max-width: ${theme.breakpoints.sm})`]: {
        display: "none",
      },
    },
  };
});

import { useEffect, useState } from "react";

import _ from "lodash";

import { useTrackerAuth } from "components/AuthProvider";

export function useTrackHeapPageLoad() {
  const { user, pending } = useTrackerAuth();
  const [hasFired, setHasFired] = useState(false);

  useEffect(() => {
    if (pending || hasFired) return;

    const isAuthenticated = !_.isNil(user);
    if (isAuthenticated) {
      (window as any).heap?.track("Client Side Loaded (Authenticated)", {
        page: window.location.pathname,
      });
    } else {
      (window as any).heap?.track("Client Side Loaded (Unauthenticated)", {
        page: window.location.pathname,
      });
    }

    setHasFired(true);
  }, [pending, user, hasFired]);
}

export function isDev() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === "dev";
}
export function isProd() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === "prod";
}

export function isLocal() {
  return Boolean(window.location.origin.match(/localhost/));
}
export function isAnon() {
  return Boolean(window.location.origin.match(/anon\.comprehensive/));
}

export const REACT_APP_API_HOSTNAME = process.env.NEXT_PUBLIC_API_HOSTNAME;
export const CURRENT_DOMAIN = typeof window !== "undefined" ? window.location.origin : undefined;
export const IS_PREVIEW = process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL === "preview";

export const BASE_ROUTE =
  REACT_APP_API_HOSTNAME && !IS_PREVIEW ? `${REACT_APP_API_HOSTNAME}` : `${CURRENT_DOMAIN}`;

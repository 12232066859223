import { createTheme } from "@mantine/core";

import { colors } from "./colors";
import { actionIconComponentSettings } from "./components/ActionIcon";
import { alertComponentSettings } from "./components/Alert";
import { anchorComponentSettings } from "./components/Anchor";
import { badgeComponentSettings } from "./components/Badge";
import { buttonComponentSettings } from "./components/Button";
import { cardComponentSettings } from "./components/Card";
import { checkboxComponentSettings } from "./components/Checkbox";
import { chipComponentSettings } from "./components/Chip";
import { codeComponentSettings } from "./components/Code";
import { datePickerInputComponentSettings } from "./components/DatePickerInput";
import { dividerComponentSettings } from "./components/Divider";
import { drawerComponentSettings } from "./components/Drawer";
import { inputWrapperComponentSettings } from "./components/InputWrapper";
import { menuComponentSettings } from "./components/Menu";
import { modalComponentSettings } from "./components/Modal";
import { multiSelectComponentSettings } from "./components/Multiselect";
import { navLinkComponentSettings } from "./components/NavLink";
import { notificationComponentSettings } from "./components/Notification";
import { numberInputComponentSettings } from "./components/NumberInput";
import { paperComponentSettings } from "./components/Paper";
import { progressComponentSettings } from "./components/Progress/Progress.settings";
import { radioComponentSettings } from "./components/Radio";
import { segmentedControlComponentSettings } from "./components/SegmentedControl";
import { selectComponentSettings } from "./components/Select";
import { switchComponentSettings } from "./components/Switch";
import { tabsComponentSettings } from "./components/Tabs";
import { textComponentSettings } from "./components/Text";
import { textInputComponentSettings } from "./components/TextInput";
import { themeIconComponentSettings } from "./components/ThemeIcon";
import { headingsThemeSettings, titleComponentSettings } from "./components/Title";
import { tooltipComponentSettings } from "./components/Tooltip";
import { variantColorResolver } from "./getVariablesForVariant";
import * as tokens from "./tokens";

export const mantineLightTheme = createTheme({
  fontFamily: "Inter, sans-serif",
  fontSizes: {
    md: "16px",
    sm: "14px",
    xs: "12px",
  },
  // commenting out - im not sure what a line height of 1.5 was
  // lineHeights: 1.5,
  radius: {
    xs: "2px",
    sm: "4px",
    md: "8px",
    lg: "6px",
    xl: "12px",
  },

  spacing: {
    xs: "2px",
    sm: "4px",
    md: "8px",
    lg: "16px",
    xl: "24px",
  },

  colors,

  other: {
    ...tokens,
  },

  shadows: {
    sm: "0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.08);",
    md: "0px 2px 8px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.18)",
    lg: "0px 12px 16px -2px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.04)",
  },

  primaryColor: "bay",
  primaryShade: 7,

  cursorType: "pointer",

  variantColorResolver,

  headings: headingsThemeSettings,
  components: {
    Anchor: anchorComponentSettings,
    Alert: alertComponentSettings,
    ActionIcon: actionIconComponentSettings,
    Badge: badgeComponentSettings,
    Button: buttonComponentSettings,
    Card: cardComponentSettings,
    Code: codeComponentSettings,
    Checkbox: checkboxComponentSettings,
    Chip: chipComponentSettings,
    DatePickerInput: datePickerInputComponentSettings,
    Drawer: drawerComponentSettings,
    Divider: dividerComponentSettings,
    Input: textInputComponentSettings,
    InputWrapper: inputWrapperComponentSettings,
    Menu: menuComponentSettings,
    Modal: modalComponentSettings,
    MultiSelect: multiSelectComponentSettings,
    Notification: notificationComponentSettings,
    NavLink: navLinkComponentSettings,
    Paper: paperComponentSettings,
    Progress: progressComponentSettings,
    Radio: radioComponentSettings,
    SegmentedControl: segmentedControlComponentSettings,
    Select: selectComponentSettings,
    Switch: switchComponentSettings,
    Tabs: tabsComponentSettings,
    Text: textComponentSettings,
    ThemeIcon: themeIconComponentSettings,
    Title: titleComponentSettings,
    Tooltip: tooltipComponentSettings,
    NumberInput: numberInputComponentSettings,
  },
});

/*
 * We don't currently manage a dark-mode theme for the sake
 * of scope, so this acts as placeholder.
 */
export const placeholderMantineDarkTheme = createTheme({
  // Dummy placeholder theme values from storybook-addon-mantine docs
  // https://github.com/josiahayres/storybook-addon-mantine/tree/4aa4d27c8d2259b48bf82a9a18e86963d220a651#pass-the-themes-to-the-addon
  primaryColor: "green",
  radius: {
    xs: "2px",
    sm: "4px",
    md: "8px",
    lg: "6px",
    xl: "12px",
  },
});

import { trpc, TrpcUseQueryArgs } from "utils/trpc";

export function useGetApplicationSettingsQuery(ops: TrpcUseQueryArgs[1] = {}) {
  return trpc.useQuery(["noAuth.getApplicationSettings"], ops);
}

export function useApplicationSettings() {
  const getApplicationSettingsQuery = useGetApplicationSettingsQuery();
  return getApplicationSettingsQuery.data || ({} as typeof getApplicationSettingsQuery.data);
}

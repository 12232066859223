import { useContext, createContext } from "react";

import type { PopoverProps, PopoverTargetProps } from "@mantine/core";
import { Popover } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

type UseDisclosureResult = ReturnType<typeof useDisclosure>;
type HoverPopoverContextValue = {
  opened: UseDisclosureResult[0];
} & Pick<UseDisclosureResult[1], "close" | "open">;

const HoverPopoverContext = createContext({});
const useHoverPopoverContext = () => useContext(HoverPopoverContext) as HoverPopoverContextValue;

export function HoverPopover({ children, opened: overrideOpened, ...rest }: PopoverProps) {
  const [rawOpened, { close, open }] = useDisclosure(false);
  const opened = overrideOpened || rawOpened;
  return (
    <Popover opened={opened} {...rest}>
      <HoverPopoverContext.Provider value={{ opened, close, open }}>
        {children}
      </HoverPopoverContext.Provider>
    </Popover>
  );
}

HoverPopover.Target = function HoverPopoverTarget({
  children: renderChildren,
  ...rest
}: Omit<PopoverTargetProps, "children"> & {
  children: (ops: Pick<HoverPopoverContextValue, "open" | "close">) => React.ReactNode;
}) {
  const { close, open } = useHoverPopoverContext();
  return <Popover.Target {...rest}>{renderChildren({ close, open })}</Popover.Target>;
};

HoverPopover.Dropdown = function HoverPopoverDropdown({
  children: renderChildren,
  ...rest
}: Omit<PopoverTargetProps, "children"> & {
  children: (ops: Pick<HoverPopoverContextValue, "open" | "close">) => React.ReactNode;
}) {
  const { close, open } = useHoverPopoverContext();
  return <Popover.Dropdown {...rest}>{renderChildren({ close, open })}</Popover.Dropdown>;
};

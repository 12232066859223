import _ from "lodash";
import { useQuery, UseQueryOptions } from "react-query";

import { User } from "prisma/cm/client";
import { useAppData } from "services/app-data";
import { useAuthenticatedUserFromReduxStore } from "services/user";
import { trpc } from "utils/trpc";
import { ObjectPoolModel, ObjectPoolModelId, ObjectPoolModelName } from "utils/types";

import {
  objectPoolEntityMapManifestQueryKey,
  replaceEntityMapManifestInQueryCache,
  useBoundQueryClient,
} from "./queryCache";

/**
 * TRPC isn't well-typed right now because of some code-health issues that
 * exist outside the scope of this work, so in the meantime we supply
 * a really dump placeholder for the where-clause and query-key.
 */
type PlaceholderTrpcWhere = { where: { companyId: User["companyId"] } };
type QueryKeyTuple = [trpcProcedurePath: string, input: PlaceholderTrpcWhere];

export function getObjectPoolTrpcProcedurePath(modelName: ObjectPoolModelName): QueryKeyTuple[0] {
  const trpcProcedurePath = ["object", _.camelCase(modelName), "findMany"].join(".");
  return trpcProcedurePath;
}

export function getObjectPoolQueryKey(
  modelName: ObjectPoolModelName,
  companyId: User["companyId"],
): QueryKeyTuple {
  const trpcProcedurePath = getObjectPoolTrpcProcedurePath(modelName);
  const queryInput = { where: { companyId } };
  return [trpcProcedurePath, queryInput];
}

const retryQueryOps: Pick<UseQueryOptions, "retry" | "retryDelay"> = {
  retry: true,
  retryDelay: (attempt) => {
    // Retry with exponential backoff up to a max.
    const intervalMs = 1000;
    const maxBackoffMs = intervalMs * 30;
    const exponentialBackoffIntervalMs = attempt > 1 ? attempt ** intervalMs : intervalMs;
    return Math.min(exponentialBackoffIntervalMs, maxBackoffMs);
  },
};

export function useGetObjectPoolManifestQuery(useQueryOptions: UseQueryOptions = {}) {
  const user = useAuthenticatedUserFromReduxStore();
  const companyId = user?.companyId as User["companyId"];
  const boundQueryClient = useBoundQueryClient();
  const manifestQuery = trpc.useQuery(
    // @ts-expect-error TS2322
    ["object.manifest.findAll", { where: { companyId }, models: [] }],
    {
      ...useQueryOptions,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...retryQueryOps,
      onSuccess: (data) => {
        // @ts-expect-error TS2345
        replaceEntityMapManifestInQueryCache(boundQueryClient, data);
      },
    },
  );
  return manifestQuery;
}

export type ObjectPoolEntityMap<TModelName extends ObjectPoolModelName> = {
  ids: Array<ObjectPoolModelId<TModelName>>;
  entities: {
    [s: string]: ObjectPoolModel<TModelName>;
    // This isn't working right now :/
    // [s: ObjectPoolModel<TModelName>["id"]]: ObjectPoolModel<TModelName>;
  };
  indexes: {
    [s in keyof ObjectPoolModel<TModelName>]?: {
      [v: string | number]: Array<ObjectPoolModelId<TModelName>>;
    };
  };
  isReady: boolean;
};

const emptyEntity = { ids: [], entities: {}, isReady: false };

export const emptyEntities = {
  AccessExtension: emptyEntity,
  AccessProfile: emptyEntity,
  AccessProfileMemberRelationship: emptyEntity,
  Benefit: emptyEntity,
  BenefitsPackage: emptyEntity,
  BenefitsPackageAssignment: emptyEntity,
  CartaEquityGrant: emptyEntity,
  Company: emptyEntity,
  CompensationApproval: emptyEntity,
  CompensationApprovalLevel: emptyEntity,
  CustomFields: emptyEntity,
  DataImport: emptyEntity,
  LogicRun: emptyEntity,
  EmailAddress: emptyEntity,
  Family: emptyEntity,
  FamilyGroup: emptyEntity,
  FieldTablePlacement: emptyEntity,
  FieldTextSetting: emptyEntity,
  Integration: emptyEntity,
  Job: emptyEntity,
  Level: emptyEntity,
  Objective: emptyEntity,
  PerformanceAnswer: emptyEntity,
  PerformanceCycle: emptyEntity,
  PerformanceQuestion: emptyEntity,
  PerformanceRating: emptyEntity,
  Proposal: emptyEntity,
  ProposerBudget: emptyEntity,
  Range: emptyEntity,
  Review: emptyEntity,
  TableSettings: emptyEntity,
  Track: emptyEntity,
  User: emptyEntity,
  Zone: emptyEntity,
};

export function useObjectPoolEntities() {
  const objectsQuery = useQuery([objectPoolEntityMapManifestQueryKey]);
  const appData = useAppData();
  // App data can be ready but empty for users with no company.
  return appData.isReady ? objectsQuery.data ?? emptyEntities : emptyEntities;
}

import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import { MantineTabler } from "app/ui/components/mantine-tabler";

export const datePickerInputComponentSettings = DatePickerInput.extend({
  defaultProps: {
    firstDayOfWeek: 0, // Sunday
    rightSection: <MantineTabler size={16} color="gray.4" icon={IconCalendar} />,
  },
  styles: {
    section: {
      // So that clicks on the calendar bubble up to the input
      // and open the calendar (rather than getting trapped and
      // doing nothing).
      pointerEvents: "none",
    },
  },
  // TODO: The weekend day color override isn't working right now because we also
  // need to override the selected state and I'm not sure how to combine the
  // two data-attribute selectors with the :not() pseudo-selector. :(
  // styles: {
  //   day: {
  //     "&[data-weekend]": {
  //       // Mantine default shows the weekend days in a different color (red)
  //       // which is surprising and feels annoying.
  //       color: "inherit",
  //     },
  //   },
  // },
});

import { Card } from "@mantine/core";

export const cardComponentSettings = Card.extend({
  defaultProps: {
    withBorder: true,
    styles: (theme) => ({
      root: {
        borderColor: theme.colors.gray[3],
      },
    }),
  },
});

import { Checkbox } from "@mantine/core";

import { formSelectionGroup } from "../../tokens";

export const checkboxComponentSettings = Checkbox.extend({
  defaultProps: {
    color: "bay.7",
  },
  styles: (theme, props) => {
    return {
      input: {
        backgroundColor: props.indeterminate ? theme.colors.gray[4] : undefined,
        borderColor: props.indeterminate ? theme.colors.gray[4] : undefined,
        cursor: "pointer",
        "&:checked": {
          backgroundColor: props.indeterminate ? theme.colors.gray[4] : props.color,
          borderColor: props.indeterminate ? theme.colors.gray[4] : props.color,
        },
        "&:disabled": {
          cursor: "auto",
        },
      },
      icon: {
        color: "white",
      },
      label: {
        paddingLeft: formSelectionGroup.labelSpacing(),
      },
      description: {
        paddingLeft: formSelectionGroup.labelSpacing(),
      },
    };
  },
});

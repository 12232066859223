import { useCallback, useState, useEffect } from "react";

import { useDisclosure } from "@mantine/hooks";
import { useRouter } from "next/router";

export const AUTH_MODAL_PARAM = "auth_modal";

interface UseQueryParamDisclosureOptions {
  paramName: string;
  addToHistory?: boolean;
}

// does this using optimistic updates instead of waiting for navigation to happen to feel snappier
function useQueryParamDisclosure({
  paramName,
  addToHistory = true,
}: UseQueryParamDisclosureOptions): ReturnType<typeof useDisclosure> {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setIsOpen(params.has(paramName) && params.get(paramName) === "true");
  }, [paramName]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const params = new URLSearchParams(new URL(url, window.location.origin).search);
      setIsOpen(params.has(paramName) && params.get(paramName) === "true");
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, paramName]);

  const open = useCallback(() => {
    setIsOpen(true);
    const newQuery = { ...router.query, [paramName]: "true" };
    if (addToHistory) {
      router.push({ pathname: router.pathname, query: newQuery });
    } else {
      router.replace({ pathname: router.pathname, query: newQuery });
    }
  }, [router, paramName, addToHistory]);

  const close = useCallback(() => {
    setIsOpen(false);
    const { [paramName]: _, ...restQueryParams } = router.query; // Remove paramName from the query params
    if (addToHistory) {
      router.push({ pathname: router.pathname, query: restQueryParams });
    } else {
      router.replace({ pathname: router.pathname, query: restQueryParams });
    }
  }, [router, paramName, addToHistory]);

  const toggle = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [isOpen, open, close]);

  return [isOpen, { open, close, toggle }];
}

export function useAuthGateDisclosure() {
  return useQueryParamDisclosure({ paramName: AUTH_MODAL_PARAM });
}

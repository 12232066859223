import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModelName, ObjectPoolModel } from "utils/types";

import {
  deleteRecordsFromQueryCache,
  getEntityMapFromQueryCache,
  replaceEntityMapInQueryCache,
  useBoundQueryClient,
} from "../queryCache";

export function useDeleteManyByIdObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const { companyId } = boundQueryClient;
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.deleteManyById`);
  const deleteManyRecordMutation = trpc.useMutation(mutationPath);

  async function mutate(ids: Array<ObjectPoolModel<TModelName>["id"]>) {
    const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);
    if (!initialEntityMapFromCache) {
      throw Error("Object Pool: Could not find collection to cache");
    }

    // Optimistic
    deleteRecordsFromQueryCache(boundQueryClient, modelName, ids);

    showUniversalLoader();
    let modelSaveResult = null;
    try {
      modelSaveResult = (await deleteManyRecordMutation.mutateAsync({
        where: { id: { in: ids }, companyId },
      })) as ObjectPoolModel<TModelName>;

      // No resolution necessary with actual save result.
    } catch (err) {
      console.error(err);
      replaceEntityMapInQueryCache<TModelName>(
        boundQueryClient,
        modelName,
        initialEntityMapFromCache,
      );
      throw err;
    }
    hideUniversalLoader();
    return modelSaveResult;
  }

  return { mutate };
}

export function generateBoundUseDeleteManyByIdObjectPoolMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBoundDeleteManyByIdObjectPoolRecord() {
    const useMutationResult = useDeleteManyByIdObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBoundDeleteManyByIdObjectPoolRecord;
}

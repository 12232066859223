import { useCallback } from "react";

import { useIntercom } from "react-use-intercom";

import { UserWithCompany } from "frontend/utils/types"; // TODO: Move this type into services/user/types
import { setDatadogSessionUser } from "lib/datadog";
import { identifyHeapUser } from "lib/heap";
import { getIntercomInstanceData } from "lib/intercom";

/**
 * Consolidate all side-effects executed after authentication, to be called
 * on login or on cookie-auth on a subsequent visit.
 */
export function useExecuteOnAuthenticateSideEffects() {
  const intercom = useIntercom();
  const executeOnAuthenticateSideEffects = useCallback(
    (user: UserWithCompany) => {
      setDatadogSessionUser(user);
      intercom.update(getIntercomInstanceData(user));
      identifyHeapUser(user);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { executeOnAuthenticateSideEffects };
}

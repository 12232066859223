import _ from "lodash";

import { hideUniversalLoader, showUniversalLoader } from "lib/frontend/universal-loader";
import { trpc, getMutationPath } from "utils/trpc";
import { ObjectPoolModelName, ObjectPoolEntityMap, ObjectPoolModel } from "utils/types";

import {
  getEntityMapFromQueryCache,
  getRecordFromQueryCache,
  replaceEntitiesInQueryCache,
  replaceEntityMapInQueryCache,
  updateRecordsInQueryCache,
  useBoundQueryClient,
} from "../queryCache";

// TODO: Rename this "batchUpdateMany"
export function useUpdateManyObjectPoolRecordMutation<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
) {
  const boundQueryClient = useBoundQueryClient();
  const mutationPath = getMutationPath(`object.${_.camelCase(modelName)}.updateManySerial`);
  const updateRecordMutation = trpc.useMutation(mutationPath);

  async function mutate(
    ids: Array<ObjectPoolModel<TModelName>["id"]>,
    updateFn: (prevValue: ObjectPoolModel<TModelName>) => Partial<ObjectPoolModel<TModelName>>,
  ) {
    const initialEntityMapFromCache = getEntityMapFromQueryCache(boundQueryClient, modelName);

    // Optimistic
    updateRecordsInQueryCache(boundQueryClient, modelName, ids, updateFn);

    showUniversalLoader();
    let modelSaveResults = {} as ObjectPoolEntityMap<TModelName>;
    try {
      const updateArgs = ids.map((id) => {
        const rec = getRecordFromQueryCache(boundQueryClient, modelName, id);
        if (!rec) throw Error("Can't find record to update.");
        return {
          where: { id },
          data: updateFn(rec),
        };
      });
      modelSaveResults = (await updateRecordMutation.mutateAsync(
        updateArgs,
      )) as ObjectPoolEntityMap<TModelName>;

      // Resolve with actual save result
      replaceEntitiesInQueryCache<TModelName>(boundQueryClient, modelName, modelSaveResults);
    } catch (err) {
      console.error(err);
      replaceEntityMapInQueryCache<TModelName>(
        boundQueryClient,
        modelName,
        initialEntityMapFromCache,
      );
      throw err;
    }
    hideUniversalLoader();
    return modelSaveResults;
  }

  return { mutate };
}

export function generateBoundUseUpdateManyObjectPoolMutation<
  TModelName extends ObjectPoolModelName,
>(modelName: TModelName) {
  function useBoundUpdateManyObjectPoolRecord() {
    const useMutationResult = useUpdateManyObjectPoolRecordMutation<TModelName>(modelName);
    return useMutationResult;
  }
  return useBoundUpdateManyObjectPoolRecord;
}

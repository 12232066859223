import { pascalCase } from "lib/string";
import type {
  AccessProfile,
  AccessProfileMemberRelationship,
  CartaEquityGrant,
  Company,
  DataImport,
  LogicRun,
  EmailAddress,
  Family,
  Integration,
  Job,
  Level,
  PerformanceAnswer,
  PerformanceCycle,
  PerformanceQuestion,
  PerformanceRating,
  Proposal,
  Range,
  Review,
  Track,
  User,
  Zone,
  Objective,
} from "prisma/cm/client";
import { Prisma } from "prisma/cm/client";
/*ModelManifest
 * Note that we don't import AccessExtension from prisma as we wish to
 * explicitly type the JSON columns.
 */
import { ModelManifest } from "prisma/cm/outputFromGenerators/posStateGraphConfig/pos-state-graph-config";
import { AccessExtension } from "services/access-extension";

/* ============ DEPRECATED - CAN DELETE ================================================ */

// Terminal Models
export type StateTreeCartaEquityGrant = CartaEquityGrant;
export type StateTreeCompany = Company;
export type StateTreeDataImport = DataImport;
export type StateTreeEmailAddress = EmailAddress;
export type StateTreeFamily = Family;
export type StateTreeIntegration = Integration;
export type StateTreeReview = Review;
export type StateTreeTrack = Track;
export type StateTreeZone = Zone;
export type StateTreeObjective = Objective;
export type StateTreePerformanceAnswer = PerformanceAnswer;
export type StateTreePerformanceCycle = PerformanceCycle;
export type StateTreePerformanceQuestion = PerformanceQuestion;
export type StateTreePerformanceRating = PerformanceRating;

// Denormalized Models
export type StateTreeLevel = Level & { track: StateTreeTrack | null };
export type StateTreeJob = Job & { family: StateTreeFamily | null; level: StateTreeLevel | null };
export type StateTreeRange = Range & {
  zone: StateTreeZone | null;
  job: StateTreeJob | null;
};

export type StateTreeProposal = Proposal;

export type StateTreeUser = User & {
  range: StateTreeRange;
  emails: StateTreeEmailAddress[];
  grants: StateTreeCartaEquityGrant[];
  proposals: StateTreeProposal[];
};
export type StateTreeAccessExtension = AccessExtension & {
  range: StateTreeRange;
  user: StateTreeUser;
};

export type StateTreeAccessProfile = AccessProfile & {
  users: StateTreeUser[];
  accessProfileMemberRelationships: StateTreeAccessProfileMemberRelationship[];
};

export type StateTreeAccessProfileMemberRelationship = AccessProfileMemberRelationship & {
  user: StateTreeUser;
  accessProfile: AccessProfile;
};

export type StateTreeModelNameToModel = {
  AccessExtension: StateTreeAccessExtension;
  AccessProfile: StateTreeAccessProfile;
  AccessProfileMemberRelationship: StateTreeAccessProfileMemberRelationship;
  CartaEquityGrant: StateTreeCartaEquityGrant;
  Company: StateTreeCompany;
  DataImport: StateTreeDataImport;
  LogicRun: LogicRun;
  EmailAddress: StateTreeEmailAddress;
  Family: StateTreeFamily;
  Integration: StateTreeIntegration;
  Job: StateTreeJob;
  Level: StateTreeLevel;
  Objective: StateTreeObjective;
  PerformanceAnswer: StateTreePerformanceAnswer;
  PerformanceCycle: StateTreePerformanceCycle;
  PerformanceQuestion: StateTreePerformanceQuestion;
  PerformanceRating: StateTreePerformanceRating;
  Proposal: StateTreeProposal;
  Range: StateTreeRange;
  Review: StateTreeReview;
  Track: StateTreeTrack;
  User: StateTreeUser;
  Zone: StateTreeZone;
};
export type StateTreeModelName = keyof StateTreeModelNameToModel;
export type StateTreeModel<TModelName extends StateTreeModelName> =
  StateTreeModelNameToModel[TModelName];
export type StateTreeModelUpdateArgs<TModelName extends StateTreeModelName> = Partial<
  StateTreeModel<TModelName>
>;

export type StateTreeAccessExtensions = Array<StateTreeAccessExtension>;
export type StateTreeAccessProfiles = Array<StateTreeAccessProfile>;
export type StateTreeAccessProfileMemberRelationships =
  Array<StateTreeAccessProfileMemberRelationship>;
export type StateTreeCartaEquityGrants = Array<StateTreeCartaEquityGrant>;
export type StateTreeEmailAddresses = Array<StateTreeEmailAddress>;
export type StateTreeFamilies = Array<StateTreeFamily>;
export type StateTreeJobs = Array<StateTreeJob>;
export type StateTreeLevels = Array<StateTreeLevel>;
export type StateTreeRanges = Array<StateTreeRange>;
export type StateTreeTracks = Array<StateTreeTrack>;
export type StateTreeUsers = Array<StateTreeUser>;
export type StateTreeZones = Array<StateTreeZone>;

export type ObjectPoolAccessExtension = ModelManifest["AccessExtension"];
export type ObjectPoolAccessProfile = ModelManifest["AccessProfile"];
export type ObjectPoolAccessProfileMemberRelationship =
  ModelManifest["AccessProfileMemberRelationship"];
export type ObjectPoolCartaEquityGrant = ModelManifest["CartaEquityGrant"];
export type ObjectPoolCompany = ModelManifest["Company"];
export type ObjectPoolDataImport = ModelManifest["DataImport"];
export type ObjectPoolLogicRun = ModelManifest["LogicRun"];
export type ObjectPoolLogicGraph = ModelManifest["LogicGraph"];
export type ObjectPoolEmailAddress = ModelManifest["EmailAddress"];
export type ObjectPoolFamily = ModelManifest["Family"];
export type ObjectPoolFamilyGroup = ModelManifest["FamilyGroup"];
export type ObjectPoolIntegration = ModelManifest["Integration"];
export type ObjectPoolJob = ModelManifest["Job"];
export type ObjectPoolLevel = ModelManifest["Level"];
export type ObjectPoolObjective = ModelManifest["Objective"];
export type ObjectPoolPerformanceAnswer = ModelManifest["PerformanceAnswer"];
export type ObjectPoolPerformanceCycle = ModelManifest["PerformanceCycle"];
export type ObjectPoolPerformanceQuestion = ModelManifest["PerformanceQuestion"];
export type ObjectPoolPerformanceRating = ModelManifest["PerformanceRating"];
export type ObjectPoolProposal = ModelManifest["Proposal"];
export type ObjectPoolRange = ModelManifest["Range"];
export type ObjectPoolReview = ModelManifest["Review"];
export type ObjectPoolTrack = ModelManifest["Track"];
export type ObjectPoolUser = ModelManifest["User"];
export type ObjectPoolZone = ModelManifest["Zone"];
export type ObjectPoolTableSettings = ModelManifest["TableSettings"];
export type ObjectPoolCustomField = ModelManifest["CustomField"];
export type ObjectPoolUserTableState = ModelManifest["UserTableState"];
export type ObjectPoolReviewTableFilterState = ModelManifest["ReviewTableFilterState"];
export type ObjectPoolFieldTablePlacement = ModelManifest["FieldTablePlacement"];
export type ObjectPoolReviewSetupConfig = ModelManifest["ReviewSetupConfig"];
export type ObjectPoolExchangeRate = ModelManifest["ExchangeRate"];
export type ObjectPoolPresence = ModelManifest["Presence"];

export const ObjectPoolModelName = Prisma.ModelName;
export type ObjectPoolModelName = Prisma.ModelName;

export type ObjectPoolModel<TModelName extends ObjectPoolModelName> = ModelManifest[TModelName];

export type ObjectPoolModelUpdateArgs<TModelName extends ObjectPoolModelName> = Partial<
  ObjectPoolModel<TModelName>
>;

export type ObjectPoolModelFields<TModelName extends ObjectPoolModelName> =
  keyof ObjectPoolModel<TModelName>;

type F = ObjectPoolModelFields<"EmailAddress">;

export type ObjectPoolModelId<TModelName extends ObjectPoolModelName> =
  ObjectPoolModel<TModelName>["id"];

export type ObjectPoolModelBatchUpdateArgs<TModelName extends ObjectPoolModelName> = Array<{
  where: {
    id: ObjectPoolModelId<TModelName>;
  };
  data: Partial<ObjectPoolModel<TModelName>>;
}>;

export type ObjectPoolEntityMap<TModelName extends ObjectPoolModelName> = {
  modelName: TModelName;
  ids: Array<ObjectPoolModelId<TModelName>>;
  entities: Record<ObjectPoolModelId<TModelName>, ObjectPoolModel<TModelName>>;
  indexes: {
    [s in keyof ObjectPoolModel<TModelName>]?: {
      [v: string | number]: Array<ObjectPoolModelId<TModelName>>;
    };
  };
  isReady: boolean;
};

export type ObjectPoolEntityMapManifest = {
  [TModelName in ObjectPoolModelName]: ObjectPoolEntityMap<TModelName>;
};

// bit of a hack since this is a "model" used by object pool
const PseudoModelNames = ["FieldTextSetting"];

function isModelName(modelName: string): modelName is ObjectPoolModelName {
  if (modelName in ObjectPoolModelName || PseudoModelNames.includes(modelName)) {
    return true;
  }

  return false;
}

export function toModelName(modelName: string): ObjectPoolModelName {
  const pascalCased = pascalCase(modelName);
  if (isModelName(pascalCased)) {
    return pascalCased;
  }
  throw new Error(`${pascalCased} is not a valid model name`);
}

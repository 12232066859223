import { datadogRum } from "@datadog/browser-rum";

import { UserWithCompany } from "frontend/utils/types";
import { isCompanyAdmin, isSuperUser } from "lib/shared/permissions";
import { User } from "prisma/cm/client";

export type DatadogSessionUserAttrs = Pick<User, "id" | "name" | "companyId"> & {
  companyName: string | null;
  primaryEmail: string | null;
  isSuperUser: boolean;
  isCompanyAdmin: boolean;
};

export function getDatadogSessionUserAttrs(user: UserWithCompany): DatadogSessionUserAttrs {
  return {
    id: user.id,
    name: user.name,
    companyId: user.companyId,
    companyName: user.company?.name,
    primaryEmail: user.emails?.find((e) => e.isPrimary)?.email || null,
    isSuperUser: isSuperUser(user),
    isCompanyAdmin: isCompanyAdmin(user),
  };
}

export function setDatadogSessionUser(user: UserWithCompany) {
  const userAttrs = getDatadogSessionUserAttrs(user);
  datadogRum.setUser(userAttrs);
  datadogRum.startSessionReplayRecording();
}

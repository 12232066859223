import _ from "lodash";

export * from "./util";

export function titleCase(str: string) {
  return _.startCase(_.camelCase(str));
}

export function pascalCase(str: string) {
  if (str === "") return "";
  const camelStr = _.camelCase(str);
  return [camelStr[0].toUpperCase(), camelStr.substring(1)].join("");
}

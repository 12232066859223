import { Drawer } from "@mantine/core";

import { getHeadingStyles } from "../Title";

export const drawerComponentSettings = Drawer.extend({
  defaultProps: {
    position: "right",
    size: 720,
    closeOnEscape: true,
    closeOnClickOutside: true,
  },
  styles: (theme, _props) => ({
    drawer: {
      // height: "100vh",
      backgroundColor: theme.colors.gray[1],
      display: "flex",
      flexDirection: "column",
    },
    body: {
      padding: 32,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: 32,
      overflow: "scroll",
    },
    header: {
      backgroundColor: "white",
      padding: 32,
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
      marginBottom: 0,
    },
    title: getHeadingStyles(theme, "h3"),
    closeButton: {},
  }),
});

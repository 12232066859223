import {
  VariantColorResolverResult,
  VariantColorsResolver,
  VariantColorsResolverInput,
  defaultVariantColorsResolver,
  parseThemeColor,
} from "@mantine/core";

import { DefaultMantineColorName } from "ui/theme/types";

type VariantHandler = (props: VariantColorsResolverInput) => Partial<VariantColorsResolver>;

export const variantColorResolver: VariantColorsResolver = (params) => {
  const { color: rawColor, variant: rawVariant } = params;
  const {
    color,
    variant,
    translated: _translated,
  } = translateSemanticVariant(rawVariant, rawColor);

  const getVariantInput: VariantColorsResolverInput = {
    ...params,
    color,
    variant: variant as string,
  };

  let resolverResult: Partial<VariantColorResolverResult> = {};
  switch (variant) {
    case "filled":
      resolverResult = getFilledVariant(getVariantInput);
      break;
    case "radiant":
      resolverResult = getRadiantVariant(getVariantInput);
      break;
    case "outline":
      resolverResult = getOutlineVariant(getVariantInput);
      break;
    case "outline-light":
      resolverResult = getOutlineLightVariant(getVariantInput);
      break;
  }

  const defaultResolved = defaultVariantColorsResolver(params);

  return {
    ...defaultResolved,
    ...(resolverResult || {}),
  };
};

const getFilledVariant: VariantHandler = ({ color, theme, autoContrast }) => {
  const parsed = parseThemeColor({ color, theme });
  if (parsed.isThemeColor && parsed.shade === undefined) {
    const bgColorIndex = color === "lime" ? 3 : color === "bay" ? 7 : 6;

    const bgColor = theme.colors[color as keyof typeof theme.colors][bgColorIndex];
    const parsedBackgroundColor = parseThemeColor({ color: bgColor, theme });

    const _autoContrast = typeof autoContrast === "boolean" ? autoContrast : theme.autoContrast;

    let textColor = _autoContrast
      ? parsedBackgroundColor.isLight
        ? "var(--mantine-color-black)"
        : "var(--mantine-color-white)"
      : "var(--mantine-color-white)";

    if (color === "lime") {
      textColor = "var(--mantine-color-bay-7)";
    }

    return {
      background: `var(--mantine-color-${color}-${bgColorIndex})`,
      hover: `var(--mantine-color-${color}-${bgColorIndex + 1})`,
      color: textColor,
      border: `calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-${color}-5)`,
    };
  }

  return {};
};

const getRadiantVariant: VariantHandler = ({ color, theme }) => {
  const parsed = parseThemeColor({ color, theme });
  if (parsed.isThemeColor && parsed.shade === undefined) {
    if (color === "gray") {
      return {
        background: `var(--mantine-color-white)`,
        hover: `var(--mantine-color-gray-2)`,
        color: `var(--mantine-color-gray-7)`,
        border: undefined,
      };
    }

    if (color === "bay") {
      return {
        background: `var(--mantine-color-${color}-3)`,
        hover: `var(--mantine-color-${color}-4)`,
        color: `var(--mantine-color-${color}-7)`,
        border: undefined,
      };
    }

    return {
      background: `var(--mantine-color-${color}-2)`,
      hover: `var(--mantine-color-${color}-3)`,
      color: `var(--mantine-color-${color}-9)`,
      border: undefined,
    };
  }

  return {};
};

const getOutlineLightVariant: VariantHandler = ({ color, theme }) => {
  const parsed = parseThemeColor({ color, theme });

  if (parsed.isThemeColor && parsed.shade === undefined) {
    if (color === "bay") {
      return {
        background: `var(--mantine-color-${color}-3)`,
        hover: `var(--mantine-color-${color}-4)`,
        color: `var(--mantine-color-${color}-7)`,
        border: getBorder(color, 7),
      };
    }

    return {
      background: `var(--mantine-color-${color}-2)`,
      hover: `var(--mantine-color-${color}-3)`,
      color: `var(--mantine-color-${color}-9)`,
      border: getBorder(color, 7),
    };
  }

  return {};
};

const getOutlineVariant: VariantHandler = ({ color, theme }) => {
  const parsed = parseThemeColor({ color, theme });

  if (parsed.isThemeColor && parsed.shade === undefined) {
    if (color === "gray") {
      return {
        background: `var(--mantine-color-white)`,
        hover: `var(--mantine-color-gray-2)`,
        color: `var(--mantine-color-gray-7)`,
        border: getBorder(color, 3),
      };
    }

    return {
      background: `var(--mantine-color-white)`,
      hover: `var(--mantine-color-${color}-2)`,
      color: `var(--mantine-color-gray-9)`,
      border: getBorder(color, 5),
    };
  }

  const fontColor =
    color === "lime" ? `var(--mantine-color-bay-7)` : `var(--mantine-color-${color}-7)`;

  const bgColorIndex = 0;
  const borderColorIndex = color === "lime" ? 5 : 7;

  return {
    background: `var(--mantine-color-white)`,
    hover: `var(--mantine-color-${color}-${bgColorIndex})`,
    color: fontColor,
    border: getBorder(color, borderColorIndex),
  };
};

export function translateSemanticVariant(
  variant: string | undefined,
  color: string | undefined,
): {
  color: DefaultMantineColorName;
  variant: string | undefined;
  translated: boolean;
} {
  if (variant === "primary") return { color: "lime", variant: "filled", translated: true };
  if (variant === "secondary") return { color: "lime", variant: "outline", translated: true };
  if (variant === "tertiary") return { color: "gray", variant: "outline", translated: true };
  if (variant === "table-control") return { color: "gray", variant: "outline", translated: true };
  if (variant === "destructive") return { color: "red", variant: "outline", translated: true };
  return { color: color as DefaultMantineColorName, variant, translated: false };
}

function getBorder(givenColor: string = "gray", givenShade: number) {
  return `calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-${givenColor}-${givenShade})`;
}

// @ts-expect-error TS2305
import type { Companies, PostsGeonames } from "@prisma/client";

import { YOE_OPTIONS } from "constants/explorer_selection_options";

// @ts-expect-error TS2307
import type { Geographies } from "./constants/regions";

export type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

export interface SortableFields {
  salaryHigh: number | null;
  salaryLow: number | null;
  salaryMid: number | null; // rangeMidpoint
  numPosts?: number;
}

export type Company = {
  name: string;
  logoUrl: string;
};

export type TitlePopularity = {
  normalizedTitle: string;
  titleCount: number;
  uniqueCompanyCount: number;
};

type NormalizedTitleRangeBase = {
  normalizedTitle: string;
  medianSalaryLow?: number;
  medianSalaryMid?: number;
  medianSalaryHigh?: number;
};

export type NormalizedTitleCompanyRange = NormalizedTitleRangeBase & {
  companyId: string;
  companyName: string;
  numCounted: number;
  companyLogoUrl: string;
};

export type NormalizedTitleStats = {
  normalizedTitle: string;
  companyCount: number;
  salaryLow: number;
  salaryHigh: number;
  postCount: number;
};

export type CompanyRangesByTitle = {
  [title: string]: NormalizedTitleCompanyRange[];
};

export type PostWithCompany = PostsGeonames & {
  company: Companies;
};

export type PostWithSlimCompany = PostsGeonames & {
  company: Pick<Companies, "id" | "name" | "logoUrl">;
  inactive_at?: string | null;
};

export type TopCompaniesByTitle = Record<string, TitleExplorerCompanyRollup[]>;

export type CompanyExplorerTitleRollup = {
  normalizedTitle: string;
  salaryLow: number | null;
  salaryMid: number | null;
  salaryHigh: number | null;
  numPosts: number;
  posts: PostWithSlimCompany[];
};

export type CompanyExplorerResult = {
  companyName: string;
  companyId: string;
  company: Companies;
  salaryLow: number | null;
  salaryMid: number | null;
  salaryHigh: number | null;
  numPosts: number;
  numTitles: number;
  titles: CompanyExplorerTitleRollup[];
};

export type TitleExplorerCompanyRollup = {
  companyName: string;
  companyId: string;
  company: Companies;
  salaryLow: number | null;
  salaryMid: number | null;
  salaryHigh: number | null;
  numPosts: number;
  posts: PostWithSlimCompany[];
};

export type TitleExplorerResult = {
  normalizedTitle: string;
  salaryLow: number | null;
  salaryMid: number | null;
  salaryHigh: number | null;
  companies: TitleExplorerCompanyRollup[];
  numCompanies: number;
  numPosts: number;
};

export type JobExplorerResult = {
  jobTitle: string;
  salaryLow: number;
  salaryHigh: number;
  locationName: string;
  companyName: string;
};

export enum ExplorerSortKey {
  RANGE_HIGH = "range_high",
  RANGE_LOW = "range_low",
  RANGE_MID = "range_mid",
  NUM_POSTS = "posts",
  ALPHABETICAL = "alphabetical",
}

export enum ExplorerSortDir {
  ASC = "asc",
  DESC = "desc",
}

export const DEFAULT_SORT_KEY = ExplorerSortKey.RANGE_HIGH;
export const DEFAULT_SORT_DIR = ExplorerSortDir.DESC;

export type QueryArgs = {
  title: string[];
  companyName: string[];
  stage: string[];
  industry: string[];
  location: Geographies[];
  tier: string[];
  headcount: string[];
  capitalRaised: [number, number] | null;
  yoe: YOE_OPTIONS[];
  requireRanges: boolean;
  sortKey: ExplorerSortKey | null;
  sortDir: ExplorerSortDir | null;
  offset: string | number | null;
  jobFamily: string[];
  level: string[];
  subJobFamily: string[];
  activeWithin: string | null;
  inactiveSince: string | null;
};

export type ExploreQueryStringParams = keyof QueryArgs;

export type JobTitleOptionValue = {
  normalizedTitle: string;
  jobFunction: string;
  count: number;
};

export type CompanyNameOptionValue = string;

export type FamilyLevelData = {
  level: string;
  jobFamily: string;
  absoluteMinLow: number | null;
  absoluteMaxHigh: number | null;
  rangeLowPercentile10: number | null;
  rangeHighPercentile10: number | null;
  rangeLowPercentile25: number | null;
  rangeHighPercentile25: number | null;
  rangeLowPercentile50: number | null;
  rangeHighPercentile50: number | null;
  rangeLowPercentile75: number | null;
  rangeHighPercentile75: number | null;
  rangeLowPercentile90: number | null;
  rangeHighPercentile90: number | null;
  numRanges: number;
  numUniqueRanges: number;
  numPosts: number;
  // postIds: string[]
};

export enum Percentiles {
  Ten = "10",
  Twenty = "20",
  TwentyFive = "25",
  Thirty = "30",
  Forty = "40",
  Fifty = "50",
  Sixty = "60",
  Seventy = "70",
  SeventyFive = "75",
  Eighty = "80",
  Ninety = "90",
}

export type SupportedPercentiles = (typeof Percentiles)[keyof typeof Percentiles];

import React from "react";

import { Box, BoxProps } from "@mantine/core";

import { useStyles } from "components/mantine-extended/use-styles";

interface PageContentBoxProps extends BoxProps {
  children: JSX.Element | JSX.Element[];
  overrideMaxWidth?: number;
}

export function PageContentBox({
  children,
  overrideMaxWidth,
  ...rest
}: PageContentBoxProps): JSX.Element {
  const { classes } = useStyles();
  return (
    <Box className={classes.pageContentBox} maw={overrideMaxWidth} {...rest}>
      {children}
    </Box>
  );
}

export function PageContentBoxWithInternalPadding({
  children,
  overrideMaxWidth,
  ...rest
}: PageContentBoxProps): JSX.Element {
  const { classes } = useStyles();
  return (
    <PageContentBox {...rest} overrideMaxWidth={overrideMaxWidth}>
      <Box className={classes.boxInternalPadding}>{children}</Box>
    </PageContentBox>
  );
}

import {
  generateUsePrismaObjectSyncFindMany,
  generateUsePrismaObjectSyncFindFirst,
  generateUsePrismaObjectSyncFindFirstOrThrow,
  ObjectPoolModelToStateGraphConfig,
} from "./queries";
import { generateBoundBatchUpdateObjectPoolRecordMutation } from "../hooks/useBatchUpdateObjectPoolRecordMutation";
import { generateBoundUseCreateManyObjectPoolMutation } from "../hooks/useCreateManyObjectPoolRecordMutation";
import { generateBoundUseCreateObjectPoolMutation } from "../hooks/useCreateObjectPoolRecordMutation";
import { generateBoundUseDeleteByIdObjectPoolMutation } from "../hooks/useDeleteByIdObjectPoolRecordMutation";
import { generateBoundUseDeleteManyByIdObjectPoolMutation } from "../hooks/useDeleteManyByIdObjectPoolRecordMutation";
import { generateBoundUseDeleteManyObjectPoolMutation } from "../hooks/useDeleteManyObjectPoolRecordMutation";
import { generateBoundUseDeleteObjectPoolMutation } from "../hooks/useDeleteObjectPoolRecordMutation";
import { generateBoundUseUpdateManyObjectPoolMutation } from "../hooks/useUpdateManyObjectPoolRecordMutation";
import { generateBoundUseUpdateObjectPoolMutation } from "../hooks/useUpdateObjectPoolRecordMutation";
import { generateBoundUseUpsertManyObjectPoolMutation } from "../hooks/useUpsertManyObjectPoolMutation";
import { generateBoundUseUpsertOneObjectPoolMutation } from "../hooks/useUpsertOneObjectPoolMutation";

export function generateUsePrismaSyncQueries<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
>(objectName: TModelName) {
  return {
    findMany: generateUsePrismaObjectSyncFindMany(objectName),
    findFirst: generateUsePrismaObjectSyncFindFirst(objectName),
    findFirstOrThrow: generateUsePrismaObjectSyncFindFirstOrThrow(objectName),
    create: generateBoundUseCreateObjectPoolMutation(objectName),
    createMany: generateBoundUseCreateManyObjectPoolMutation(objectName),
    update: generateBoundUseUpdateObjectPoolMutation(objectName),
    updateMany: generateBoundUseUpdateManyObjectPoolMutation(objectName),
    upsertMany: generateBoundUseUpsertManyObjectPoolMutation(objectName),
    upsertOne: generateBoundUseUpsertOneObjectPoolMutation(objectName),
    delete: generateBoundUseDeleteObjectPoolMutation(objectName),
    deleteById: generateBoundUseDeleteByIdObjectPoolMutation(objectName),
    deleteMany: generateBoundUseDeleteManyObjectPoolMutation(objectName),
    batchUpdate: generateBoundBatchUpdateObjectPoolRecordMutation(objectName),

    // TO DEPRECATE
    deleteManyById: generateBoundUseDeleteManyByIdObjectPoolMutation(objectName),
  };
}

export const usePrismaObjectSync = {
  accessExtension: generateUsePrismaSyncQueries("AccessExtension"),
  accessProfile: generateUsePrismaSyncQueries("AccessProfile"),
  accessProfileMemberRelationship: generateUsePrismaSyncQueries("AccessProfileMemberRelationship"),
  benefit: generateUsePrismaSyncQueries("Benefit"),
  benefitAssignment: generateUsePrismaSyncQueries("BenefitAssignment"),
  exchangeRate: generateUsePrismaSyncQueries("ExchangeRate"),
  benefitsPackage: generateUsePrismaSyncQueries("BenefitsPackage"),
  company: generateUsePrismaSyncQueries("Company"),
  companyOnboardingConfig: generateUsePrismaSyncQueries("CompanyOnboardingConfig"),
  compensationApproval: generateUsePrismaSyncQueries("CompensationApproval"),
  compensationApprovalLevel: generateUsePrismaSyncQueries("CompensationApprovalLevel"),
  customField: generateUsePrismaSyncQueries("CustomField"),
  dataImport: generateUsePrismaSyncQueries("DataImport"),
  family: generateUsePrismaSyncQueries("Family"),
  familyGroup: generateUsePrismaSyncQueries("FamilyGroup"),
  fileUpload: generateUsePrismaSyncQueries("FileUpload"),
  grantType: generateUsePrismaSyncQueries("GrantType"),
  integration: generateUsePrismaSyncQueries("Integration"),
  job: generateUsePrismaSyncQueries("Job"),
  level: generateUsePrismaSyncQueries("Level"),
  logicGraph: generateUsePrismaSyncQueries("LogicGraph"),
  logicRun: generateUsePrismaSyncQueries("LogicRun"),
  objective: generateUsePrismaSyncQueries("Objective"),
  performanceAnswer: generateUsePrismaSyncQueries("PerformanceAnswer"),
  performanceCycle: generateUsePrismaSyncQueries("PerformanceCycle"),
  performanceQuestion: generateUsePrismaSyncQueries("PerformanceQuestion"),
  performanceRating: generateUsePrismaSyncQueries("PerformanceRating"),
  presence: generateUsePrismaSyncQueries("Presence"),
  proposal: generateUsePrismaSyncQueries("Proposal"),
  proposerBudget: generateUsePrismaSyncQueries("ProposerBudget"),
  range: generateUsePrismaSyncQueries("Range"),
  review: generateUsePrismaSyncQueries("Review"),
  reviewSetupConfig: generateUsePrismaSyncQueries("ReviewSetupConfig"),
  reviewTableFilterState: generateUsePrismaSyncQueries("ReviewTableFilterState"),
  tableSettings: generateUsePrismaSyncQueries("TableSettings"),
  emailAddress: generateUsePrismaSyncQueries("EmailAddress"),
  userTableState: generateUsePrismaSyncQueries("UserTableState"),
  // @ts-expect-error TS2345
  fieldTextSetting: generateUsePrismaSyncQueries("FieldTextSetting"),
  fieldTablePlacement: generateUsePrismaSyncQueries("FieldTablePlacement"),
  track: generateUsePrismaSyncQueries("Track"),
  user: generateUsePrismaSyncQueries("User"),
  zone: generateUsePrismaSyncQueries("Zone"),
};

import { MantineTheme } from "@mantine/styles";

export const colors: Partial<MantineTheme["colors"]> = {
  gray: [
    "#ffffff",
    "#fafafc",
    "#f0f2f2",
    "#d9dbdb",
    "#b4b8b7",
    "#7c807f",
    "#585e5d",
    "#2a2e2d",
    "#191c1c",
    "#000000",
  ],

  // This is a replication of bay below, but is now supported by Button
  // This can be removed or we can refer to it again as `bay`
  brand: [
    "#f7fcfc",
    "#e9f7f5",
    "#caebe6",
    "#a7dbd4",
    "#7fc7bd",
    "#3d857b",
    "#326b63",
    "#26524c",
    "#1c3d39",
    "#000000",
  ],

  bay: [
    "#f7fcfc",
    "#e9f7f5",
    "#caebe6",
    "#a7dbd4",
    "#7fc7bd",
    "#3d857b",
    "#326b63",
    "#26524c",
    "#1c3d39",
    "#000000",
  ],

  red: [
    "#fef5f5",
    "#ffe3e3",
    "#ffc9c9",
    "#ffa8a8",
    "#ff8787",
    "#fe6b6c",
    "#fa5352",
    "#eb3d3d",
    "#d9302f",
    "#b82727",
  ],

  // @ts-expect-error TS2322
  proposal: ["#fef6ee", "#FFE6D5", "#B93815"],
  // @ts-expect-error TS2322
  approval: ["#f4f3ff", "#EBE9FE", "#7a5af8"],
  // @ts-expect-error TS2322
  finalized: ["#ecfdf3", "#D1FADF", "#12b76a"],

  // @ts-expect-error TS2322
  fifteenFive: ["#D8D7EA", "#1A0D3F"],
};

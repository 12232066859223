import { useEffect } from "react";

import {
  Affix,
  getDefaultZIndex,
  Loader,
  LoaderProps,
  OptionalPortal,
  PortalProps,
} from "@mantine/core";

import {
  UniversalLoaderStore,
  universalLoaderStore,
  useUniversalLoader,
} from "./universalLoader.store";

export interface UniversalLoaderProps extends LoaderProps {
  withinPortal?: boolean;
  portalProps?: Omit<PortalProps, "children" | "withinPortal">;
  zIndex?: React.CSSProperties["zIndex"];
  store?: UniversalLoaderStore;
  top: number;
  right: number;
}

export function UniversalLoader({
  size = "sm",
  withinPortal = true,
  portalProps,
  zIndex = getDefaultZIndex("max"),
  store = universalLoaderStore,
  top,
  right,
  ...others
}: UniversalLoaderProps) {
  const state = useUniversalLoader(store);

  useEffect(() => {
    store.initialize({
      mounted: false,
      visible: false,
      color: "white",
    });
  }, [store]);

  return (
    <OptionalPortal {...portalProps} withinPortal={withinPortal}>
      <Affix position={{ top, right }} zIndex={zIndex}>
        {state.visible && (
          <Loader
            data-mounted={state.mounted || undefined}
            color={state.color}
            z={zIndex}
            size={size}
            {...others}
          />
        )}
      </Affix>
    </OptionalPortal>
  );
}

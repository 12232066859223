import { Stack, UnstyledButton } from "@mantine/core";
import Logo from "app/ui/assets/logo/logo-wide-white.svg";
import Image from "next/image";
import { Link as RouterLink } from "react-router-dom";

interface Props extends Partial<React.ComponentProps<typeof Image>> {
  height?: number;
  width?: number;
  defaultRoute?: string;
}

export function HeaderLogoButton({
  height = 32,
  width = 130,
  defaultRoute = "/",
  ...imageProps
}: Props) {
  return (
    <Stack h="100%" justify="center">
      <UnstyledButton component={RouterLink} to={defaultRoute}>
        <Image
          priority
          alt="Comprehensive"
          height={height}
          width={width}
          src={Logo}
          // @ts-expect-error TS2322
          fit="contain"
          sx={{ objectPosition: "left center" }}
          {...imageProps}
        />
      </UnstyledButton>
    </Stack>
  );
}

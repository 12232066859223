import _ from "lodash";

type ConstrainImageDimensionsParams = {
  height: number;
  width: number;
} & (
  | {
      newHeight: number;
      newWidth?: never;
    }
  | {
      newHeight?: never;
      newWidth: number;
    }
);
export function constrainImageDimensions({
  height,
  width,
  newHeight,
  newWidth,
}: ConstrainImageDimensionsParams): { height: number; width: number } {
  if (!_.isUndefined(newHeight)) {
    return {
      height: newHeight,
      width: (newHeight * width) / height,
    };
  }
  if (!_.isUndefined(newWidth)) {
    return {
      height: (height * newWidth) / width,
      width: newWidth,
    };
  }
  throw Error("Could not compute new dimensions");
}

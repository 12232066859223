import { CreateTRPCClientOptions, TRPCClient, createTRPCClient } from "@trpc/client";
import { httpLink } from "@trpc/client/links/httpLink";
import { createReactQueryHooks } from "@trpc/react";

import type { TrpcAppRouter } from "@/trpc";
import { BASE_ROUTE } from "lib/env";

export const trpc = createReactQueryHooks<TrpcAppRouter>();

export type TrpcUseMutationArgs = Parameters<typeof trpc.useMutation>;
export type TrpcMutationPath = TrpcUseMutationArgs[0];

export type TrpcUseQueryArgs = Parameters<typeof trpc.useQuery>;
export type TrpcQueryPath = TrpcUseMutationArgs[0];

// Remove duplicate /api/ from route, temporary until we have time to look at why this happens on Vercel
const trpcBaseUrl = `${BASE_ROUTE}/api/trpc`.replace("/api/api/", "/api/");

function getTrpcClientParams() {
  const params: CreateTRPCClientOptions<TrpcAppRouter> = {
    url: trpcBaseUrl,
    fetch(url, options) {
      // Send cookies cross-origin
      // @see https://trpc.io/docs/v9/cors
      return fetch(url, {
        ...options,
        credentials: "include",
      });
    },
    links: [
      httpLink<TrpcAppRouter>({
        url: trpcBaseUrl,
      }),
    ],
  };
  return params;
}

export function createTrpcReactClient() {
  return trpc.createClient(getTrpcClientParams());
}

export function createTrpcVanillaClient(): TRPCClient<TrpcAppRouter> {
  return createTRPCClient<TrpcAppRouter>(getTrpcClientParams());
}
export const trpcVanilla = createTrpcVanillaClient();

export type CmTrpcReactClient = ReturnType<typeof createTrpcReactClient>;

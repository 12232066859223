import { useCallback, useEffect, useState } from "react";

import { sleep } from "@hen/stdlib/promise";

import { defaultMantineTransitionDuration } from "../lib/mantine";

/**
 * Delays a value-change in state to prevent immediate a UI-change
 * on the initial render.
 *
 * This is particularly helpful in scenarios like transitions
 * where you don't want the content of an element to change while
 * the element is transitioning. (In such a scenario, use the
 * original state value to manage the transition and the delayed
 * value to manage the content.)
 */
export function useDelayedValue<TValue = unknown>(
  value: TValue,
  { getDelay }: { getDelay: (value: TValue) => number },
) {
  const [delayedValue, setDelayedValue] = useState<TValue>(value);

  const handleChange = useCallback(async () => {
    const delay = getDelay(value);
    await sleep(delay);
    setDelayedValue(value);
  }, [value, getDelay]);

  useEffect(() => {
    handleChange();
  }, [value, handleChange]);

  return delayedValue;
}

export function useSafeDelayedValue<TValue = unknown>(
  value: TValue,
  ops: { getDelay: (value: TValue) => number },
) {
  const delayedValue = useDelayedValue(value, ops);
  return value || delayedValue;
}

export function useSafeDelayedValueOnModalClose<TValue = unknown>(value: TValue) {
  return useSafeDelayedValue(value, {
    getDelay: () => defaultMantineTransitionDuration,
  });
}

import { createStore, MantineStore, useStore } from "@mantine/store";

export interface UniversalLoaderState {
  visible: boolean;
  mounted: boolean;
  color: string;
}

export type UniversalLoaderStore = MantineStore<UniversalLoaderState>;

export const createUniversalLoaderStore = () =>
  createStore<UniversalLoaderState>({
    visible: false,
    mounted: false,
    color: "white",
  });

export const useUniversalLoader = (store: UniversalLoaderStore) => useStore(store);

export function updateUniversalLoaderStateAction(
  update: (state: UniversalLoaderState) => Partial<UniversalLoaderState>,
  store: UniversalLoaderStore,
) {
  const state = store.getState();
  store.setState({ ...state, ...update(store.getState()) });
}

export function showLoaderAction(store: UniversalLoaderStore) {
  updateUniversalLoaderStateAction((_s) => ({ visible: true, mounted: true }), store);
}

export function hideLoaderAction(store: UniversalLoaderStore) {
  updateUniversalLoaderStateAction(
    (_s) => ({
      visible: false,
    }),
    store,
  );
}

export function setColorAction(color: string, store: UniversalLoaderStore) {
  updateUniversalLoaderStateAction((_s) => ({ color }), store);
}

export function createUniversalLoader() {
  const store = createUniversalLoaderStore();
  const actions = {
    showUniversalLoader: () => showLoaderAction(store),
    hideUniversalLoader: () => hideLoaderAction(store),
    setUniversalLoaderColor: (color: string) => setColorAction(color, store),
  };

  return [store, actions] as const;
}

export const [universalLoaderStore, universalLoader] = createUniversalLoader();

export const { showUniversalLoader, hideUniversalLoader, setUniversalLoaderColor } =
  universalLoader;

import { MantineTheme, Switch } from "@mantine/core";

export const switchComponentSettings: MantineTheme["components"]["Switch"] = Switch.extend({
  defaultProps: {
    color: "brand.8",
  },
  styles: {
    track: {
      // Note that Mantine applies the disabled cursor style
      // so we don't need to.
      cursor: "pointer",
    },
  },
});
